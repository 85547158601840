import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Input,
  Radio,
  Tooltip,
  Checkbox,
} from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState, useReducer } from 'react';
import { logSharedResponse, notifySdkError } from '../../../../utils';
import {
  postbackAPI,
  transactionAPI,
  mfAnalyticsAPI,
} from '../../../../utils/apiService';
import { getCookie } from '../../../../utils/cookieService';
import { FetchStateMap, MFDataReducerActionType } from './utils';
import MfPostbackSection from './MfPostbackSection';
import MfAnalyticsSection from './MfAnalyticsSection';

const MF_HOLDINGS_IMPORT = 'MF_HOLDINGS_IMPORT';
const sections = {
  POSTBACK_SECTION: 'POSTBACK_SECTION',
  ANALYTICS_SECTION: 'ANALYTICS_SECTION',
};

const getConfigObject = (fromDate, pan, phone, responseConfig) => {
  const returnObj = {};
  if (fromDate) returnObj.fromDate = fromDate.format('DD-MMM-YYYY');
  if (pan && phone) Object.assign(returnObj, { pan, phone });
  if (responseConfig) returnObj.responseConfig = responseConfig;
  if (Object.keys(returnObj).length === 0) return null;
  return returnObj;
};

const initialMFData = {
  notes: null,
  fromDate: null,
  pan: null,
  phone: null,
  responseConfig: {
    casDetail: true,
    casSummary: false,
  },
};

const mfDataReducer = (mfData, action) => {
  switch (action.type) {
    case MFDataReducerActionType.SET_NOTES: {
      return {
        ...mfData,
        notes: action.payload,
      };
    }
    case MFDataReducerActionType.SET_FROM_DATE: {
      return {
        ...mfData,
        fromDate: action.payload,
      };
    }
    case MFDataReducerActionType.SET_PAN_NUMBER: {
      return {
        ...mfData,
        pan: action.payload,
      };
    }
    case MFDataReducerActionType.SET_MOBILE_NUMBER: {
      return {
        ...mfData,
        phone: action.payload,
      };
    }
    case MFDataReducerActionType.SET_RESPONSE_CONFIG: {
      return {
        ...mfData,
        responseConfig: {
          ...mfData.responseConfig,
          ...action.payload,
        },
      };
    }
  }
};

export default function MutualFunds() {
  const [mfData, dispatch] = useReducer(mfDataReducer, initialMFData);
  const [transactionId, setTransactionId] = useState(null);
  const [postBackData, setPostBackData] = useState(null);
  const [postbackFetchState, setPostBackFetchState] = useState(
    FetchStateMap.INITIAL,
  );

  const [transactionIdForAnalytics, setTransactionIdForAnalytics] = useState(
    null,
  );
  const [analyticsFetchState, setAnalyticsFetchState] = useState(
    FetchStateMap.INITIAL,
  );
  const [mfAnalyticsData, setMfAnalyticsData] = useState(null);
  const [openedSection, setOpenedSection] = useState(sections.POSTBACK_SECTION);
  const [updateResponseConfig, setUpdateResponseConfig] = useState(false);

  const triggerMfImport = () => {
    transactionAPI(
      getCookie('userID'),
      MF_HOLDINGS_IMPORT,
      getConfigObject(
        mfData.fromDate,
        mfData.pan,
        mfData.phone,
        updateResponseConfig && mfData.responseConfig,
      ),
      mfData.notes,
      null,
    )
      .then(({ transactionId }) => {
        setTransactionId(transactionId);
        return window.sc.triggerMfTransaction({ transactionId });
      })
      .then(res => logSharedResponse('Holdings Response', res))
      .catch(err => {
        notifySdkError(err);
        throw err;
      });
  };

  const searchPostBack = transactionId => {
    setPostBackFetchState(FetchStateMap.LOADING);
    postbackAPI(transactionId).then(data => {
      logSharedResponse('Postback Data', data);
      setPostBackData(data);
      if (data && Object.keys(data).length) {
        setPostBackFetchState(FetchStateMap.SUCCESS);
      } else {
        setPostBackFetchState(FetchStateMap.EMPTY);
      }
    });
  };

  const getMFAnalyticsFn = transactionId => {
    setAnalyticsFetchState(FetchStateMap.LOADING);
    mfAnalyticsAPI(transactionId).then(data => {
      logSharedResponse('Mutual Funds Analytics Data', data);
      setMfAnalyticsData(data);
      if (data && Object.keys(data).length) {
        setAnalyticsFetchState(FetchStateMap.SUCCESS);
      } else {
        setAnalyticsFetchState(FetchStateMap.EMPTY);
      }
    });
  };

  const handleOpenedSection = sectionName => {
    setOpenedSection(sectionName);
  };

  const handleNotesChange = notes => {
    dispatch({
      type: MFDataReducerActionType.SET_NOTES,
      payload: notes,
    });
  };

  const handlePostbackAnalyticsSection = {
    [sections.POSTBACK_SECTION]: (
      <MfPostbackSection
        postbackFetchState={postbackFetchState}
        postBackData={postBackData}
      />
    ),
    [sections.ANALYTICS_SECTION]: (
      <MfAnalyticsSection
        analyticsFetchState={analyticsFetchState}
        mfAnalyticsData={mfAnalyticsData}
      />
    ),
  };

  return (
    <div className="container">
      <Card>
        <Card>
          <Col md={20} sm={24}>
            <Row>
              <Col md={8} sm={24}>
                <DatePicker
                  placeholder="From date"
                  value={mfData.fromDate}
                  onChange={date => {
                    dispatch({
                      type: MFDataReducerActionType.SET_FROM_DATE,
                      payload: date,
                    });
                  }}
                />
              </Col>
              <Col md={{ span: 12, offset: 3 }} sm={24}>
                <Input
                  placeholder="Optional Notes"
                  value={mfData.notes}
                  onChange={e => handleNotesChange(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mt8">
              <Col md={10} sm={24}>
                <Tooltip
                  trigger={['focus']}
                  title="If PAN Number is filled, then Mobile Number must have to be filled."
                  placement="topLeft"
                  overlayClassName="numeric-input"
                >
                  <Input
                    addonBefore="PAN Number"
                    placeholder="If PAN Number is filled, then Mobile Number must have to be filled."
                    value={mfData.pan}
                    onChange={e => {
                      dispatch({
                        type: MFDataReducerActionType.SET_PAN_NUMBER,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col md={{ span: 12, offset: 1 }} sm={24}>
                <Tooltip
                  trigger={['focus']}
                  title="If Mobile Number is filled, then PAN Number must have to be filled."
                  placement="topLeft"
                  overlayClassName="numeric-input"
                >
                  <Input
                    addonBefore="Mobile Number"
                    placeholder="If Mobile Number is filled, then PAN Number must have to be filled."
                    value={mfData.phone}
                    onChange={e => {
                      dispatch({
                        type: MFDataReducerActionType.SET_MOBILE_NUMBER,
                        payload: e.target.value,
                      });
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col className="mt8">
            <Button
              type="primary"
              onClick={triggerMfImport}
              className="ios-click mr8"
              title="synchronise mf holdings snapshot with MFCentral"
            >
              Trigger MF import
            </Button>
          </Col>
          <Card>
            <Row>
              <Col sm={24}>
                <Checkbox
                  defaultChecked={false}
                  onChange={e => {
                    setUpdateResponseConfig(e.target.checked);
                  }}
                >
                  Update Response Config
                </Checkbox>
              </Col>
              <Col className="mt8" sm={12}>
                <Checkbox
                  defaultChecked={true}
                  disabled={!updateResponseConfig}
                  onChange={e => {
                    dispatch({
                      type: MFDataReducerActionType.SET_RESPONSE_CONFIG,
                      payload: { casDetail: e.target.checked },
                    });
                  }}
                >
                  Get Cas Details
                </Checkbox>
              </Col>
              <Col className="mt8" sm={12}>
                <Checkbox
                  defaultChecked={false}
                  disabled={!updateResponseConfig}
                  onChange={e => {
                    dispatch({
                      type: MFDataReducerActionType.SET_RESPONSE_CONFIG,
                      payload: { casSummary: e.target.checked },
                    });
                  }}
                >
                  Get Cas Summary
                </Checkbox>
              </Col>
            </Row>
          </Card>
        </Card>
        <Card>
          <Row>
            <Col md={11} sm={24}>
              <Search
                value={transactionId}
                onChange={e => setTransactionId(e.target.value)}
                placeholder="enter transaction id"
                enterButton="Search in postback"
                onSearch={value => searchPostBack(value)}
              />
            </Col>
            <Col md={{ span: 11, offset: 2 }} sm={24}>
              <Search
                value={transactionIdForAnalytics}
                onChange={e => setTransactionIdForAnalytics(e.target.value)}
                placeholder="enter transaction id"
                enterButton="Get analytics"
                onSearch={value => getMFAnalyticsFn(value)}
              />
            </Col>
          </Row>
        </Card>
      </Card>
      <Card className="mt8">
        <div>
          <Radio.Group
            defaultValue={`${sections.POSTBACK_SECTION}`}
            size="small"
            onChange={e => handleOpenedSection(e.target.value)}
          >
            <Radio.Button value={sections.POSTBACK_SECTION}>
              Postback Data
            </Radio.Button>
            <Radio.Button value={sections.ANALYTICS_SECTION}>
              Analytics Data
            </Radio.Button>
          </Radio.Group>
        </div>
        {handlePostbackAnalyticsSection[openedSection]}
      </Card>
    </div>
  );
}
