import { Button, Input, Typography } from 'antd';
import React, { useState } from 'react';

const message = 'close my loan';

const CloseLoan = ({ triggerLoanClose }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <div>
      <Typography.Title level={4}>Close Loan</Typography.Title>
      <Typography.Paragraph>
        To close loan, enter <b>{message}</b> & submit.
        <br />
        <b>Note</b>: You will still need to send closure email to Bajaj (contact
        loan ops for help). Email trigger is not automated for smartinvesting.
      </Typography.Paragraph>
      <Input
        placeholder={`Type in \`${message}\``}
        onChange={e =>
          e.target.value === message
            ? setIsDisabled(false)
            : setIsDisabled(true)
        }
      />
      <Button onClick={triggerLoanClose} disabled={isDisabled} type="danger">
        Request Closure
      </Button>
    </div>
  );
};

export default CloseLoan;
