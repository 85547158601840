export const SchemeDetailsColumns = [
    { title: 'ISIN', dataIndex: 'isin', key: 'isin' },
    { title: 'Scheme', key: 'scheme', dataIndex: 'scheme', width: 200 },
    { title: 'Scheme Code', key: 'schemeCode', dataIndex: 'schemeCode' },
    { title: 'AMC', key: 'amc', dataIndex: 'amc' },
    { title: 'AMC Code', key: 'amcCode', dataIndex: 'amcCode' },
    { title: 'Asset Type', key: 'assetType', dataIndex: 'assetType' },
    { title: 'Entry Load', key: 'entryLoad', dataIndex: 'entryLoad' },
    { title: 'Exit Load', key: 'exitLoad', dataIndex: 'exitLoad' },
    { title: 'Units', key: 'units', dataIndex: 'units' },
    { title: 'NAV', key: 'nav', dataIndex: 'nav' },
    { title: 'Current Value', key: 'currentValue', dataIndex: 'currentValue' },
    { title: 'Fund Type', key: 'fundType', dataIndex: 'fundType' },
    { title: 'Exit Load Remarks', key: 'exitLoadRemarks', dataIndex: 'exitLoadRemarks' },
    { 
      title: 'Analytics', 
      key: 'analytics', 
      width: 100,
      children: [
        { title: 'Invested Value', dataIndex: 'analytics.investedValue', key: 'analytics.investedValue' },
        { title: 'Average Price', dataIndex: 'analytics.averagePrice', key: 'analytics.averagePrice' },
        { title: 'Time stamp', dataIndex: 'analytics.timestamp', key: 'analytics.timestamp' },
        { title: 'Current Returns', dataIndex: 'analytics.currentReturns', key: 'analytics.currentReturns' },
        { title: 'Current Returns Percentage', dataIndex: 'analytics.currentReturnsPercentage', key: 'analytics.currentReturnsPercentage' },
        { title: 'Remarks', dataIndex: 'analytics.remarks', key: 'analytics.remarks' },
        { title: 'XIRR', dataIndex: 'analytics.xirr', key: 'analytics.xirr' },
        { title: 'Scheme Weight', dataIndex: 'analytics.schemeWeight', key: 'analytics.schemeWeight' },
      ] 
    },
];

export const ExitedTableColumns = [
    { title: 'ISIN', dataIndex: 'isin', key: 'isin' },
    { title: 'Folio Number', dataIndex: 'folioNumber', key: 'folioNumber' },
    { title: 'Scheme', dataIndex: 'scheme', key: 'scheme' },
    { title: 'Scheme Code', dataIndex: 'schemeCode', key: 'schemeCode' },
    { title: 'AMC', dataIndex: 'amc', key: 'amc' },
    { title: 'AMC Code', dataIndex: 'amcCode', key: 'amcCode' },
    { title: 'Asset Type', dataIndex: 'assetType', key: 'assetType' },
    { title: 'Broker Name', dataIndex: 'brokerName', key: 'brokerName' },
    { title: 'Broker Code', dataIndex: 'brokerCode', key: 'brokerCode' },
    { title: 'Entry Load', dataIndex: 'entryLoad', key: 'entryLoad' },
    { title: 'Exit Load', dataIndex: 'exitLoad', key: 'exitLoad' },
    { title: 'IS Demat', dataIndex: 'isDemat', key: 'isDemat' },
    { title: 'Current Value', dataIndex: 'currentValue', key: 'currentValue' },
    { title: 'Units', dataIndex: 'units', key: 'units' },
    { title: 'NAV', dataIndex: 'nav', key: 'nav' },
    { title: 'NAV Updated Date', dataIndex: 'navUpdatedDate', key: 'navUpdatedDate' },
    { title: 'AVG Buy Nav', dataIndex: 'avgBuyNav', key: 'avgBuyNav' },
    { title: 'Returns', dataIndex: 'returns', key: 'returns' },
    { title: 'Returns Percent', dataIndex: 'returnsPercent', key: 'returnsPercent' },
    { title: 'XIRR', dataIndex: 'xirr', key: 'xirr' },
    { title: 'SIP Frequency', dataIndex: 'sipFrequency', key: 'sipFrequency' },
    { title: 'Next SIP Date', dataIndex: 'nextSipDate', key: 'nextSipDate' },
];

export const PortfolioTableColumns = [
    { title: 'Scheme Count', dataIndex: 'schemeCount', key: 'schemeCount', align: 'center' },
    { title: 'XIRR', dataIndex: 'xirr', key: 'xirr', align: 'center' }, 
];

export const SchemeDetailsFolioColumns = [
    { title: "Folio Number", dataIndex: "folioNumber", key: "folioNumber", align: 'center' },
    { title: "Broker Name", dataIndex: "brokerName", key: "brokerName", align: 'center' },
    { title: "Broker Code", dataIndex: "brokerCode", key: "brokerCode", align: 'center' },
    { title: "IS Demat", dataIndex: "isDemat", key: "isDemat", align: 'center' },
    { title: "Units", dataIndex: "units", key: "units", align: 'center' },
];
