import { Button, Card, notification } from 'antd';
import React from 'react'
import { errorWithDetails, logSharedResponse, notifySdkError } from '../../../../utils';
import { transactionAPI } from '../../../../utils/apiService';
import { getCookie } from '../../../../utils/cookieService';
const TRANSACTION = 'TRANSACTION'
const RECONCILIATION = 'RECONCILIATION'
const Reconciliation = (props) => {

    const triggerReconciliation =() =>{
      if(props.loggedIn){
        transactionAPI(getCookie('userID'), TRANSACTION, {
          type: RECONCILIATION,
          },
        )
          .then((data) => {
            return data.transactionId
          })
          .then(transactionId => {

           return  window.sc.triggerTransaction({
            transactionId,
          })}
          )
          .then(res => {
            logSharedResponse("Order Response", res)
            console.log(res)
          })
          .catch(err => {
            console.log(err.message)
            switch (err.message) {
              case 'invalid_transaction':
                notification.error({
                  message: 'Invalid Transaction',
                  description: errorWithDetails('You must be having multiple instances of same stock in your order.' ,err),
                  duration: 5,
                  placement: 'topRight'
                });
                break;
              case 'invalid_user_data':
                notification.error({
                  message: 'Login Error',
                  description: errorWithDetails('You must login before trying to place an order' ,err),
                  duration: 5,
                  placement: 'bottomRight'
                })
                break;
              case 'market_closed':
                notification.error({
                  message: 'Markets Closed',
                  description: errorWithDetails('Markets are closed right now, please try again on the next market session' ,err),
                  duration: 5,
                  placement: 'topRight'
                })
                break;
              case 'no_shortfall':
                notification.error({
                  message: 'No shortfall detected',
                  description: errorWithDetails('No Shortfall was detected.' ,err),
                  duration: 5,
                  placement: 'bottomRight'
                })
                break;
              case 'no_broker':
                notification.error({
                  message: 'No Broker 😞',
                  description: errorWithDetails('Doesn\'t have a broker account or broker not supported yet' ,err),
                  duration: 5,
                  placement: 'topRight'
                })
                break;
              default: {
                  notifySdkError(err);
                }
            }
           
          })
        }else{
          notification.error({
            message: 'Uh oh!',
            description: 'Please login to continue',
            duration: 1,
            placement: 'bottomRight'
          })
        }

      }



  
  return (
    <div className="container">
    <Card
        title="Holdings Reconciliation"
        extra={
            <div style={{ display: 'flex'}}>
                <Button
                type="primary"
                onClick={triggerReconciliation}
                title="The purpose of a Positions/Holdings & Trades reconciliation is to check and verify that smallcase is in sync of number of securities as with your Broker"> 
                    Reconcile Holdings 
                </Button>
            </div>
        }
        className="mt8">
 <p>
 When a user sells a stock through the broker, it does not reflect automatically in the smallcase account. This flow would help to reconcile the holdings and get your smallcase account in sync with your broker account.
 </p>
 </Card>
</div>
    );
  
}

export default Reconciliation