import React from 'react';
import { Card, Table } from 'antd';

const { Column, ColumnGroup } = Table;

const MfUserInfoTable = ({ userInfo }) => {
  return (
    <Card title="User Info">
      {userInfo && Object.keys(userInfo).length ? (
        <Table bordered dataSource={[userInfo]} scroll={{ x: 1800 }}>
          <Column title="PAN" dataIndex="pan" key="pan" />
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Phone" dataIndex="phone" key="phone" />
          <Column title="Email" dataIndex="email" key="email" />

          <ColumnGroup title="Address">
            <Column
              title="Address line 1"
              dataIndex="address.address1"
              key="address.address1"
            />
            <Column
              title="Address line 2"
              dataIndex="address.address2"
              key="address.address2"
            />
            <Column
              title="Address line 3"
              dataIndex="address.address3"
              key="address.address3"
            />
            <Column title="City" dataIndex="address.city" key="address.city" />
            <Column
              title="District"
              dataIndex="address.district"
              key="address.district"
            />
            <Column
              title="State"
              dataIndex="address.state"
              key="address.state"
            />
            <Column
              title="Pincode"
              dataIndex="address.pincode"
              key="address.pincode"
            />
            <Column
              title="Country"
              dataIndex="address.country"
              key="address.country"
            />
          </ColumnGroup>
        </Table>
      ) : (
        'user info not found'
      )}
    </Card>
  );
};

export default MfUserInfoTable;
