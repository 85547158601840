import React, { useState, useEffect } from 'react'
import qs from 'query-string';
import { Card, Button, Input, Table, notification , Icon} from 'antd'
import { postbackAPI } from '../../../../utils/apiService'
import './postback.css'
import { logSharedResponse } from '../../../../utils';

function fetchUserHoldings(transactionId, setPostbackData) {
    postbackAPI(transactionId)
        .then((data) => {
            logSharedResponse('Postback Data', data);
            setPostbackData(data);
        })
}

function handleTransactionID(event, setTransactionId) {
    const { value } = event.target;
    setTransactionId(value.trim())
}

export default function Postback(props) {
    const [holdings, setPostbackData] = useState({});
    const [transactionId, setTransactionId] = useState('');
    useEffect(() => {
        const queryParams = qs.parse(props.location.search);
        const { transactionId: trxid } = queryParams;
        setTransactionId(trxid);
    }, []);

    

    const ordersColumn = [
        {
            title: 'Trading Symbol',
            dataIndex: 'tradingsymbol',
            key: 'tradingsymbol'
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            key: 'transactionType'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            key: 'exchange'
        },
        {
            title: 'Order Type',
            dataIndex: 'orderType',
            key: 'orderType'
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Filled Quantity',
            dataIndex: 'filledQuantity',
            key: 'filledQuantity'
        },
        {
            title: 'Exchange OrderId',
            dataIndex: 'exchangeOrderId',
            key: 'exchangeOrderId'
        },
    ];

    const copyToClipboard = (id, valueTitle, value) =>{
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        notification.success({
            message: `Succesfully copied ${valueTitle}`,
            description: value,
            duration: 2,
            placement: 'bottomRight'
          });
    }


    return (
        <div className="container">
            <Card title="Postback">
                <Card style={{ maxWidth: '450px', width: '100%'}} className="mt8">
                    <Input
                        value={transactionId}
                        onChange={(event) => handleTransactionID(event, setTransactionId)}
                        className="mr8"
                        style={{ width: 200 }}
                    />
                    <Button type="primary" onClick={() => fetchUserHoldings(transactionId, setPostbackData)} className="ios-click mt8"> Check Postback </Button>
                </Card>
                <Card style={{ minHeight: '300px'}} className="mt8">
                    <h3 className='mb20'>Info</h3>
                    { Object.keys(holdings).length > 0 &&
                        <div className={'table-container'}>
                            <table className='postback-info-container'>
                                <tr className={'postback-info-container-item'}>
                                    <th><h3>Key </h3></th>
                                    <th><h3>Value</h3></th>
                                    <td className='showOnHover'></td>
                                </tr>
                                <tr className={'postback-info-container-item'}  id='batchId' onClick={() => copyToClipboard('batchId', 'Batch Id' ,holdings.batchId)}>
                                    <td>Batch Id</td>
                                    <th>
                                        {holdings.batchId}
                                        <span className='showOnHover'> <Icon type="copy" /> click to copy</span>
                                    </th>
                                    
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Buy Amount</td>
                                    <th>{holdings.buyAmount}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Sell Amount</td>
                                    <th>{holdings.sellAmount}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Quantity</td>
                                    <th>{holdings.quantity}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Filled</td>
                                    <th>{holdings.filled}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Status</td>
                                    <th>{holdings.status}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Variety</td>
                                    <th>{holdings.variety}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Completed Date</td>
                                    <th>{holdings.completedDate}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Orders</td>
                                    <th>{holdings.orders.length}</th>
                                    
                                </tr>
                                <tr className={'postback-info-container-item'}>
                                    <td>Unplaced</td>
                                    <th>{holdings.unplaced.length}</th>
                                </tr>
                                <tr className={'postback-info-container-item'}  id='transactionId' onClick={() => copyToClipboard('transactionId', 'transactionId' ,holdings.transactionId)}>
                                    <td>Transaction Id</td>
                                    <th>{holdings.transactionId} <span className='showOnHover'> <Icon type="copy" /> click to copy</span></th>
                                    
                                </tr>
                                <tr className={'postback-info-container-item'}  onClick={() => copyToClipboard('smallcaseAuthId', 'smallcase AuthId' ,holdings.smallcaseAuthId)}>
                                    <td>smallcase AuthId</td>
                                    <th>{holdings.smallcaseAuthId} <span className='showOnHover'><Icon type="copy" /> click to copy</span></th>
                                </tr>
                            </table>
                        </div>
                    }

                    <h3 className='mt40 mb20'>Orders</h3>
                    <Table 
                        bordered

                        dataSource={holdings.orders}
                        columns={ordersColumn}
                        pagination={false}
                    />

                    <h3  className='mt40 mb20'>Unplaced</h3>
                    <Table 
                        bordered
                        dataSource={holdings.unplaced}
                        columns={ordersColumn}
                        pagination={false}
                    />
                    {/* <ReactJson src={holdings}  /> */}
                </Card>
            </Card>
        </div>
    )
}