import React, { useState , useEffect} from 'react'
import { Card, Button, Input, Icon, Statistic, Table, Col , Row, Modal} from 'antd'
import { historicalOrdersAPI} from '../../../../utils/apiService'
import { getCookie } from '../../../../utils/cookieService'
import './HistoricalOrders.css';

export default class HistoricalOrders extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            transactionId : '',
            batchID : '',
            batches: [],
            modalVisible: false,
        };
    }

    handleTextInputChange = (field ,value) =>{
        this.setState({
            [field]: value
        })
    }

    handleFetch = () =>{
        historicalOrdersAPI(this.state.transactionId, this.state.batchID, getCookie('userID'))
        .then( res =>{
            this.setState({
                batches: res.data.data.batches
            });
        })
        .catch( err => {
            this.setState({
                modalVisible: true
            })
        });
    }

    closeModal = () =>{
        this.setState({
            modalVisible: false
        })
    }

    render(){
        const columns = [
            {
              title: 'Filled',
              dataIndex: 'filled',
              key: 'filled',
            },
            {
              title: 'Variety',
              dataIndex: 'variety',
              key: 'variety',
            },
            {
              title: 'Buy Amount',
              dataIndex: 'buyAmount',
              key: 'buyAmount',
            },
            {
              title: 'Sell Amount',
              dataIndex: 'sellAmount',
              key: 'sellAmount',
            },
            {
              title: 'Batch Id',
              dataIndex: 'batchId',
              key: 'batchId',
            },
            {
              title: 'Qty',
              dataIndex: 'quantity',
              key: 'quantity',
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
            },
            {
              title: 'Completed Date',
              dataIndex: 'completedDate',
              key: 'completedDate',
            },
            {
              title: 'Transaction Id',
              dataIndex: 'transactionId',
              key: 'transactionId',
            },
        ];

        const ordersColumn = [
            {
                title: 'Trading Symbol',
                dataIndex: 'tradingsymbol',
                key: 'tradingsymbol'
            },
            {
                title: 'Transaction Type',
                dataIndex: 'transactionType',
                key: 'transactionType'
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: 'Exchange',
                dataIndex: 'exchange',
                key: 'exchange'
            },
            {
                title: 'Order Type',
                dataIndex: 'orderType',
                key: 'orderType'
            },
            {
                title: 'Product',
                dataIndex: 'product',
                key: 'product'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status'
            },
            {
                title: 'Filled Quantity',
                dataIndex: 'filledQuantity',
                key: 'filledQuantity'
            },
            {
                title: 'Exchange OrderId',
                dataIndex: 'exchangeOrderId',
                key: 'exchangeOrderId'
            },
        ];

        const expandedRow = (record, index, indent, expanded) =>{
            return <div>
                <h3 className='inner-headers'>Orders</h3>
                <Table 
                    // bordered
                    pagination={false}
                    size='small'
                    dataSource={record.orders}
                    columns={ordersColumn}
                />
            </div>
        }

        return (
            <div className="container">
                <Card
                    title={'Historical Orders'} 
                >
                    <Row>
                        <Col  xs={24} sm={12}>
                            <Card>
                                <div className='inputsContainer' >
                                    <Input
                                        className='inputBoxes'
                                        placeholder="TransactionId"
                                        onChange={e => this.handleTextInputChange('transactionId' ,e.target.value)}
                                    />
                                    
                                    <Input 
                                        className='inputBoxes'
                                        placeholder="Batch Id"
                                        onChange={e => this.handleTextInputChange('batchID',e.target.value)}
                                    />

                                    <Button
                                        type="primary"
                                        className="ios-click fetchBtn"
                                        style={{marginRight: '25px'}}
                                        onClick={this.handleFetch}
                                    > 
                                        Fetch Details 
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '16px'}}>
                        <Col>
                            { this.state.batches.length > 0 && 
                                <Card title={'Batches'}>
                                    <Table
                                        bordered
                                        dataSource={this.state.batches} columns={columns}
                                        expandedRowRender={expandedRow}
                                        scroll={{ x: 1200}}
                                    />
                                </Card>
                            }
                        </Col>
                    </Row>
                </Card>
                <Modal
                    title="Uh oh ! ⚠️"
                    visible={this.state.modalVisible}
                    onCancel={this.closeModal}
                    footer={null}
                    maskClosable={true}
                >
                    <p>Transaction ID / Batch ID might not exist</p>
                </Modal>
            </div>
        );
    }
}