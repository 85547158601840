import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { verifyAccessToken } from '../../../../utils/apiService';

const MfAccessForm = ({ onVerifyToken }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);
  return (
    <div className="container">
      <Input
        placeholder="Access Token"
        value={token}
        size='large'
        onChange={evt => {
          setToken(evt.target.value.trim());
        }}
      />
      <Button
        className="mt8 mr8 ios-click"
        disabled={token === ''}
        onClick={() => {
          verifyAccessToken(token)
            .then((data) => {
              if (data.success) {
                onVerifyToken(true);
              } else {
                setError('Invalid token');
              }
            })
            .catch(e => {
              setError('Invalid token');
            });
        }}
      >
        Submit
      </Button>
      {error}
    </div>
  );
};

export default MfAccessForm;
