import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Card, Avatar, Button, Table, notification, Row, Col, Tabs } from 'antd'
import { transactionAPI } from '../../../../utils/apiService'
import { getCookie } from '../../../../utils/cookieService'
import { errorWithDetails, logSharedResponse, notifySdkError } from '../../../../utils'
import { parseToLocaleDateString } from '../../../../utils/textFormating'
import HistoricalPlot from '../../../../components/HistoricalPlot'

import "./Profile.css";

const { Meta } = Card
const { TabPane } = Tabs;

const TRANSACTION = 'TRANSACTION'
const SUBSCRIPTION = 'SUBSCRIPTION'

class Profile extends Component {
	state = {
		loading: true,
		smallcase: null,
		investment: null,
		news: null,
	}

	componentDidMount() {
		this.fetchSmallcaseProfileData()
	}

	componentDidUpdate(prevProps) {
		if (this.props.login !== prevProps.login) {
			this.setState({
				loading: true
			})
			this.fetchSmallcaseProfileData()
		}
	}

	fetchSmallcaseProfileData = () => {
		return window.sc.getSmallcaseProfile({
			scid: this.props.match.params.scid
		})
			.then(smallcase => {
				let investment = null
				if (window.sc.INVESTED_SMALLCASES) {
					for (let investmentData of window.sc.INVESTED_SMALLCASES) {
						if (investmentData.scid === smallcase.scid) {
							investment = investmentData
							break
						}
					}
				}
				this.setState({
					smallcase,
					loading: false,
					investment,
				})
			})
	}

	fetchSmallcaseNews = () => {
		return window.sc.getNews({scid: this.props.match.params.scid}).then(({news}) => this.setState({news}));
	}

	handleTabChange = (activeKey) => {
		if(activeKey === '2') {
			this.fetchSmallcaseNews();
		}
	}

	handleTransaction = async ({ transactionId }) => {
		this.setState({ transactionId });
		try {
			const res = await window.sc.triggerTransaction({ transactionId });
			logSharedResponse("Order response", res);
			this.fetchSmallcaseProfileData();
		} catch(err) {
			if (err.message === 'invalid_user_data') {
				notification.error({
					message: 'Login Error',
					description: errorWithDetails('You must login before trying to place an order', err),
					duration: 5,
					placement: 'bottomRight'
				})
			} else notifySdkError(err);
			if (err.message === 'user_cancelled') {
				this.fetchSmallcaseProfileData()
			}
			throw err;
		}
	}

	triggerOrder = (type) => {
		if (type === 'investmore') {
			this.props.history.push('/investments/' + this.state.investment._id)
			return
		} else if (type === 'subscribe') {
			transactionAPI(getCookie('userID'), SUBSCRIPTION, {
					scid: this.props.match.params.scid,
			  })
				.then(this.handleTransaction)
		} else {
			transactionAPI(getCookie('userID'), TRANSACTION, {
				type,
				iscid: this.state.investment?.investment?.iscid,
				scid: this.props.match.params.scid,
			  })
				.then(this.handleTransaction)
		}
	}

 	render() {
		const {
			loading,
			smallcase,
			investment,
			news,
		} = this.state
		const { login } = this.props

		return (
			<div className="container">
				<Row>
					<Col xs={24} >
							<Card loading={loading} title="Smallcase Profile">
								{
									!loading && (
										<div className="flex-apart">
											<div>
												<Meta
													title={smallcase.info.name}
													description={smallcase.info.publisherName}
													avatar={<Avatar
														src={smallcase.imageUrl}
														shape="square"
														size="large"
													/>}
												/>
												<p style={{margin: '1em 0 0'}}>{smallcase.info.shortDescription}</p>
											</div>
											<div className="button">
												{smallcase.flags.private && (
													<Button
														type="primary"
														size={'large'}
														className="mt8 mr8 ios-click"
														onClick={() => this.triggerOrder('subscribe')}
													>
														Subscribe
													</Button>
												)}
												<Button
													type="primary"
													size={'large'}
													className="mt8 mr8 ios-click"
													onClick={() => this.triggerOrder(login && investment ? 'investmore' : 'buy')}
												>
													{
														login && investment ? 'View Investment' : 'Buy'
													}
												</Button>
											</div>
										</div>
									)
								}
								</Card>
							<Card style={{marginTop: '20px'}}>
								<Tabs defaultActiveKey="1" onChange={this.handleTabChange}>
									<TabPane tab="Rationale" key="1">
									<div dangerouslySetInnerHTML={{__html:smallcase?.rationale}}></div>
									</TabPane>
									<TabPane tab="News" key="2">
										<div className="news-container">
											{news ? news.map(newsItem => 
											<Card
											key={newsItem.date}
											className="news-card"
											cover={
												<img
												alt="News article header"
												className="news-header-img"
												src={newsItem.imageUrl}
												/>}
											>
												<Meta
													title={newsItem.headline}
													description={parseToLocaleDateString(newsItem.date)}
													/>
												<p className="news-summary">{newsItem.summary}</p>	
											</Card>) : 'loading...'}
										</div>
									</TabPane>
									<TabPane tab="Performance" key="3">
										<HistoricalPlot scid={this.props.match.params.scid} benchmark={smallcase?.benchmark} />
									</TabPane>
									{(!loading && login && smallcase.constituents) && (
									<TabPane tab="Constituents" key="10">
										
											<Table
												columns={[
													{
														title: 'Stock Name',
														dataIndex: 'stockName',
														key: 'stockName',
													}, {
														title: 'Shares',
														dataIndex: 'shares',
														key: 'shares',
													}, {
														title: 'Weight',
														dataIndex: 'weight',
														key: 'weight',
													}
												]}
												dataSource={[
													...smallcase.constituents
												]}
												pagination={false}
												rowKey={'stockName'}
												size={'small'}
												scroll={{ x: 2}}
											/>
									</TabPane>
									)
								}
								</Tabs>
							</Card>
								

					</Col>
				</Row>
			</div>
		)
	}
}

export default withRouter(Profile)
