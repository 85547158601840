import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Card, Table, notification, Select, InputNumber, AutoComplete, Button, Row, Col, Modal, Tag, PageHeader, Input, Collapse, Switch } from 'antd'

import imgSrc from '../../../assets/success-img.png';
import { searchAPI, transactionAPI } from '../../../utils/apiService'
import { getCookie } from '../../../utils/cookieService'

import './Create.css'
import { logSharedResponse, notifySdkError, handleErrorMessage } from '../../../utils';

const { Option } = Select
const { Panel } = Collapse

const TRANSACTION = 'TRANSACTION'
const AUTHORISE_HOLDINGS = 'AUTHORISE_HOLDINGS'

const orderColumns = [{
  title: 'Trading Symbol',
  dataIndex: 'tradingSymbol',
  key: 'tradingSymbol',
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  render: tag => { return (<Tag color={tag.toLowerCase() === 'complete' ? 'green' : 'red'}> {tag.toUpperCase()} </Tag>) }
}];

function holdingsAuth() {
  const id = getCookie('userID')
	return transactionAPI(id, AUTHORISE_HOLDINGS)
		.then(({ transactionId }) => window.sc.triggerTransaction({
			transactionId,
		}))
		.then(res => {
      console.log(">>>", res)
      notification.success({
        message: 'Holdings Authorized ✅',
        description: 'All holdings are now authorized for selling',
        duration: 5,
        placement: 'topRight'
      })
    })
    .catch((err)=>{
			notifySdkError(err);
      throw err;
    });
}


class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stocks: [],
      type: 'BUY',
      quantity: 1,
      orderType: 'MARKET',
      price: undefined,
      triggerPrice: undefined,
      stock: "",
      stockResult: [],
      visible: false,
      enableQty: true,
      enableType: true,
      enableLimitOrder: true,
      orders: [],
      transactionId: null,
      notes: "",
      receivedNotes: "",
      basketName: "",
      basketLogo: "",
      tidToTrigger: "",
    }

    this.transactionText = React.createRef()
  }

  triggerOrder = () => {
    const { stocks, notes, basketName, basketLogo } = this.state;
    if (stocks.length > 0) {
      transactionAPI(getCookie('userID'), TRANSACTION, {
        type: 'SECURITIES',
        securities: stocks,
        meta: {
          orderName: basketName,
          orderLogo: basketLogo,
        },
      }, notes)
        .then(({ transactionId }) => {
          this.setState({
            transactionId,
          });
          return transactionId;
        })
        .then(transactionId => window.sc.triggerTransaction({
          transactionId,
        }))
        .then(res => {
          const [lastBatch] = res.orderBatches;
          const orders = lastBatch.orders
          logSharedResponse("Order Response", res)
          this.setState({
            visible: true,
            orders: orders.map(order => ({ tradingSymbol: order.tradingsymbol, status: order.status })),
            receivedNotes: res.notes,
          })
        })
        .catch(err => {
          handleErrorMessage(err);
          console.log(err);
        })
    } else {
      notification['error']({
        message: 'No stock added',
        description: 'First add a stock before you can transact',
        placement: 'bottomLeft',
      });
    }
  }

  addStock = () => {
    if (this.state.stock !== "") {
      this.setState(state => {
        const {
          stocks, stock, type, quantity, orderType, price, triggerPrice,
          enableType, enableQty, enableLimitOrder,
        } = state;
        return {
          stocks: [
            ...stocks,
            {
              ticker: stock,
              ...(enableType && { type: type }),
              ...(enableQty && { quantity: quantity }),
              ...(enableLimitOrder && { orderType: orderType }),
              ...(enableLimitOrder && { price: price }),
              ...(enableLimitOrder && { triggerPrice: triggerPrice }),
              validity: orderType === 'MARKET' ? 'IOC' : 'DAY',
            }
          ],
          stock: "",
          triggerPrice: undefined,
          price: undefined,
          orderType: 'MARKET',
          type: 'BUY',
          stockResult: [],
        }
      }
      )
    } else {
      notification['error']({
        message: 'No stock selected',
        description: 'Search for stock name and select from dropdown first',
        placement: 'bottomLeft',
      });
    }
  }

  handleTypeChange = (type) => {
    this.setState({
      type,
    })
  }

  handleOrderTypeChange = (orderType) => {
    this.setState({ orderType });
  }

  handlePriceChange = (price) => {
    this.setState({ price });
  }

  handleTriggerPriceChange = (triggerPrice) => {
    this.setState({ triggerPrice });
  }

  handleQuantityChange = (quantity) => {
    this.setState({
      quantity,
    })
  }

  handleStockName = (stock) => {
    this.setState({
      stock,
    })
  }

  handleSwitch = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  handleSearch = (value) => {
    this.setState({
      stock: value,
    }, () => {
      if (value && value.length >= 3) {
        searchAPI(value)
          .then(res => res.results)
          .then(stocks => (stocks.map(stock => stock.stock.info.ticker)))
          .then(stockResult => this.setState({
            stockResult,
          }));
      }
    })
  }

  handleDelete = (index) => {
    this.setState(state => {
      const stocks = [...state.stocks];
      stocks.splice(index, 1);
      return {
        stocks
      }
    })
  };

  closeModal = () => {
    this.setState({
      visible: false,
    })
  }

  handleNotesChange = (notes) => {
    this.setState({
      notes
    });
  }

  handleShowOrders = () => {
    window.sc.showOrders();
  }

  handleBasketNameChange = (basketName) => {
    this.setState({ basketName });
  }

  handleBasketLogoChange = (basketLogo) => {
    this.setState({ basketLogo });
  }

  handleTidToTriggerChange = (tidToTrigger) => {
    this.setState({ tidToTrigger });
  }

  triggerTransaction = () => {
    window.sc.triggerTransaction({
      transactionId: this.state.tidToTrigger
    })
    .catch(err => {
      handleErrorMessage(err);
    })
  }

  render() {
    const {
      stocks,
      stock,
      type,
      orderType,
      price,
      triggerPrice,
      stockResult,
      basketLogo,
      basketName,
      notes,
      tidToTrigger,
    } = this.state
    return (
      <div className="container">
        <Card title="Create" extra={
          <div>
            <Button type="secondary" onClick={holdingsAuth}> Authorize Holdings </Button>
            <Button type="secondary" className="ml16" onClick={this.handleShowOrders}> Show Orders </Button>
            <Button type="primary" className="ml16" onClick={this.triggerOrder}> Place Order </Button>
          </div>
        } bodyStyle={{
          padding: '12px'
        }}>
          <Row
            type="flex"
          >
            <Col sm={24} style={{flexGrow: 1, padding: '8px'}}>
              <Collapse>
                <Panel header={<h2 style={{marginTop: '6px'}}> Basket Configration <span role='img' aria-label='tool'>🔧</span> </h2>}>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px', padding: '0 8px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Quantity</h3>
                        <Switch
                          checked={this.state.enableQty}
                          disabled={this.state.stocks.length > 0}
                          onChange={(e) => { this.handleSwitch('enableQty', e) }}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Order Type</h3>
                        <Switch
                          checked={this.state.enableType}
                          disabled={this.state.stocks.length > 0}
                          onChange={(e) => { this.handleSwitch('enableType', e) }}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Limit Order</h3>
                        <Switch
                          checked={this.state.enableLimitOrder}
                          disabled={this.state.stocks.length > 0}
                          onChange={(e) => { this.handleSwitch('enableLimitOrder', e) }}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Name</h3>
                        <Input
                          placeholder="Enter Basket Name"
                          style={{marginLeft: '16px'}}
                          value={basketName}
                          onChange={ e => {this.handleBasketNameChange(e.target.value)}}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Logo</h3>
                        <Input
                          placeholder="Enter Logo URL"
                          style={{marginLeft: '16px'}}
                          value={basketLogo}
                          onChange={ e => {this.handleBasketLogoChange(e.target.value)}}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col md={24} lg={8} style={{flexGrow: 1, padding: '0 8px'}}>
                    <Card style={{marginBottom: '16px'}}>
                      <div className='add-stock-controller-item'>
                        <h3 className='add-stock-controller-item-title'>Notes</h3>
                        <Input
                          placeholder="Optional Notes"
                          style={{marginLeft: '16px'}}
                          value={notes}
                          onChange={ e => {this.handleNotesChange(e.target.value)}}
                        />
                      </div>
                    </Card>
                  </Col>
                </Panel>
              </Collapse>
            </Col>
            <Col sm={24} lg={12} style={{flexGrow: 1}}>
              <div className='actions-container'>
                <h2 className='action-header'>
                  Add Stocks
                  </h2>
                <div className='action-body'>
                  <div className={'add-stock-table'}>
                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Stock</h3>
                      <div className='add-stock-item'>
                        <AutoComplete
                          dataSource={stockResult}
                          onSelect={this.handleStockName}
                          onSearch={this.handleSearch}
                          placeholder="Search Stocks"
                          className='input-item'
                          value={stock}
                        />
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Quantity</h3>
                      <div className='add-stock-item'>
                        <InputNumber
                          min={1}
                          defaultValue={1}
                          onChange={this.handleQuantityChange}
                          className='input-item'
                          disabled={!this.state.enableQty}
                        />
                      </div>
                    </div>
                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Type</h3>
                      <div className='add-stock-item'>
                        <Select className='input-item' value={type} disabled={!this.state.enableType} onChange={this.handleTypeChange}>
                          <Option value="BUY">Buy</Option>
                          <Option value="SELL">Sell</Option>
                        </Select>
                      </div>
                    </div>

                <Collapse className="limit-order-collapse">
                  <Panel header="Limit order config (optional) »" showArrow={false}>
                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Order Type</h3>
                      <div className='add-stock-item'>
                        <Select className='input-item' value={orderType} disabled={!this.state.enableLimitOrder} onChange={this.handleOrderTypeChange}>
                          <Option value="MARKET">Market</Option>
                          <Option value="LIMIT">Limit</Option>
                          <Option value="SL">Stoploss</Option>
                          <Option value="SLM">Stoploss - Market</Option>
                        </Select>
                      </div>
                    </div>

                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Trigger Price</h3>
                      <div className='add-stock-item'>
                        <InputNumber
                          min={0}
                          step="0.5"
                          value={triggerPrice}
                          onChange={this.handleTriggerPriceChange}
                          className='input-item'
                          disabled={!this.state.enableLimitOrder}
                        />
                      </div>
                    </div>

                    <div className={'add-stock-row'}>
                      <h3 className='add-stock-item add-stock-controller-item-title'>Price</h3>
                      <div className='add-stock-item'>
                        <InputNumber
                          min={0}
                          step="0.5"
                          value={price}
                          onChange={this.handlePriceChange}
                          className='input-item'
                          disabled={!this.state.enableLimitOrder}
                        />
                      </div>
                    </div>
                  </Panel>
              </Collapse>
                  </div>
                  <Button className='add-stock-btn' type="primary" onClick={this.addStock}> Add </Button>
                </div>
              </div>
            </Col>
            <Col sm={24} lg={12} style={{flexGrow: 1}} id="postback-section">
              <div className='actions-container'>
                <h2 className='action-header'>
                  TransactionID
                </h2>
                <div className='action-body'>
                  <p
                    onClick={(e) => {
                      const selection = window.getSelection();
                      selection.removeAllRanges();
                      const range = document.createRange();
                      range.selectNode(e.target);
                      selection.addRange(range);
                    }}
                    style={{overflowWrap: 'anywhere'}}
                  >
                    {this.state.transactionId || 'Not Available'}
                  </p>
                  <Button
                    className='add-stock-btn'
                    type="primary"
                    onClick={() => {
                      const { transactionId } = this.state
                      if (transactionId) {
                        this.props.history.push(`/postback?transactionId=${transactionId}`);
                      } else {
                        notification['error']({
                          message: 'No transactionId found',
                          description: 'First trigger a transaction',
                          placement: 'bottomLeft',
                        });
                      }
                    }}
                  >
                    Search Postback
                  </Button>
                </div>
                <div style={{ marginTop: '52px' }} className="action-body">
                  <h2 className='action-header'>
                    Trigger a transactionID
                  </h2>
                  <div className='add-stock-controller-item'>
                    <Input
                      placeholder="Enter Transaction ID"
                      value={tidToTrigger}
                      onChange={ e => {this.handleTidToTriggerChange(e.target.value)}}
                    />
                    <Button
                      type="primary"
                      style={{ marginLeft: '16px' }}
                      onClick={this.triggerTransaction}
                    >Trigger</Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} style={{flexGrow: 1}}>
              { stocks.length > 0 && <Table
                columns={[
                  {
                    title: 'Ticker',
                    dataIndex: 'ticker',
                    key: 'ticker',
                  }, {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                  }, {
                    title: 'Order Type',
                    dataIndex: 'orderType',
                    key: 'orderType',
                  }, {
                    title: 'Price',
                    dataIndex: 'price',
                    key: 'price',
                  }, {
                    title: 'Trig. Price',
                    dataIndex: 'triggerPrice',
                    key: 'triggerPrice',
                  }, {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                  }, {
                    title: 'Action',
                    key: 'action',
                    render: (text, record, index) => (
                      <a onClick={e => { this.handleDelete(index) }} className="ios-click">
                        Delete
                      </a>
                    ),
                  },
                ]}
                dataSource={[
                  ...stocks,
                ]}
                pagination={false}
                rowKey={'ticker'}
                size={'small'}
                scroll={{ x: 2 }}
                className="mt8 m-lr-8"
              /> }
            </Col>
          </Row>
        </Card>
        <Modal
          title={null}
          footer={null}
          visible={this.state.visible}
          onCancel={this.closeModal}
          style={{ textAlign: 'center' }}
        >
          <div style={{
            width: '200px',
            margin: '50px auto 0'
          }}>
            <img src={imgSrc} alt="success" style={{
              width: '200px'
            }} />
          </div>
          <PageHeader title="Order successful for following stocks" style={{ textAlign: 'center', marginTop: 20 }} />
          <h3
            onClick={(e) => {
              const selection = window.getSelection();
              selection.removeAllRanges();
              const range = document.createRange();
              range.selectNode(e.target);
              selection.addRange(range);
            }}
            style={{ overflow: 'scroll', marginBottom: '25px' }}
          >
            {this.state.receivedNotes || 'No notes passed'}
          </h3>
          {
            <div style={{ height: '350px' }}>
              <Table columns={orderColumns} dataSource={this.state.orders} pagination={{ pageSize: 4, position: 'top' }} />
            </div>
          }
        </Modal>
      </div>
    )
  }
}

export default withRouter(Create)
