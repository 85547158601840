import React from 'react';
import { FetchStateMap } from './utils';
import MfSummaryTable from './MfSummaryTable';
import MfTransactionTable from './MfTransactionTable';

const MfAnalyticsSection = ({ analyticsFetchState, mfAnalyticsData }) => {
    return <div>
        {(() => {
            switch (analyticsFetchState) {
              case FetchStateMap.INITIAL:
                return <p className="mt8">Use a transaction id to search for analytics data</p>;
              case FetchStateMap.EMPTY:
                return <p className="mt8">Couldnt find any data in analytics, please wait and try again</p>;
              case FetchStateMap.LOADING:
                return <p className="mt8">Searching analytics...</p>;
              case FetchStateMap.SUCCESS:
                return (
                  <>
                    <MfSummaryTable
                      exited={mfAnalyticsData.summary.exited}
                      portfolio={mfAnalyticsData.summary.portfolio}
                      schemeDetails={mfAnalyticsData.summary.schemeDetails}
                    />
                    <MfTransactionTable
                      transactions={mfAnalyticsData.transactions}
                    />
                  </>
                );
            }
          })()}
    </div>
}

export default MfAnalyticsSection;
