import React from 'react';
import { Card, Table } from 'antd';

const { Column } = Table;

const getStatusInfoArray = ({
  snapshotDate,
  transactionId,
  gateway,
  checksum,
}) => {
  return [
    {
      snapshotDate,
      transactionId,
      gateway,
      checksum,
    },
  ];
};

const MfImportStatusTable = ({postbackData,
}) => {
  return (
    <Card title="Webhook status">
      <Table
        bordered
        dataSource={getStatusInfoArray(postbackData)}
        scroll={{ x: 1800 }}
        rowKey={()=>"status-row"}
      >
        <Column
          title="Snapshot Date"
          dataIndex="snapshotDate"
          key="snapshotDate"
        />
        <Column title="Txn Id" dataIndex="transactionId" key="transactionId" />
        <Column title="Gateway" dataIndex="gateway" key="gateway" />
        <Column title="checksum" dataIndex="checksum" key="checksum" />
      </Table>
    </Card>
  );
};

export default MfImportStatusTable;
