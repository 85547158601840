const fetchSdkType = () => {
  const sdkType = new URLSearchParams(document.location.search).get('sdk');
  return sdkType;
};

const sdkTypes = {
  BETA: 'beta',
  LAMF: 'las',
  DEFAULT: 'default',
};

const isBetaSdk = () => {
  return fetchSdkType() === sdkTypes.BETA;
};

const isLamfSdk = () => {
  return fetchSdkType() === sdkTypes.LAMF;
};
const isDefaultSdk = () => {
  return !(isBetaSdk() || isLamfSdk());
};

export { fetchSdkType, sdkTypes, isBetaSdk, isLamfSdk, isDefaultSdk };
