import React from 'react';
import { Button, Card, Form, Input, Modal } from 'antd';

const OldUserForm = ({ form, onSubmit, isLoading, isVisible, onCancel }) => {
  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };
  return (
    <Modal
      visible={isVisible}
      title="Select registered user"
      footer={null}
      onCancel={onCancel}
    >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 },
          }}
          onSubmit={handleSubmit}
          labelAlign="left"
        >
          <Form.Item label="User Id">
            {getFieldDecorator('userId', {})(<Input />)}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 8,
                offset: 16,
              },
            }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              Select
            </Button>
          </Form.Item>
        </Form>
    </Modal>
  );
};

const WrappedForm = Form.create({ name: 'select-las-user' })(OldUserForm);
export default WrappedForm;
