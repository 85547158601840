import { Table } from 'antd';
import React from 'react';

const SummaryTable = ({ summary = {} }) => {
  const data = Object.entries(summary).map(([key, value]) => ({
    key,
    value,
  }));

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <Table
      scroll={{ x: 'max-content' }}
      dataSource={data}
      columns={columns}
      pagination={false}
      showHeader={false}
    />
  );
};

export default SummaryTable;
