import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Checkbox,
  Divider,
  Radio,
} from 'antd';
import React, { useState } from 'react';
import { randomIdStr } from '../../../../utils';
import { parseNewUserForm, validateIfJson } from './utils';

const { TextArea } = Input;

const NewUserForm = ({ form, onSubmit, isLoading, extra }) => {
  const [emailIds, setEmailsIds] = useState([]);
  const [phoneList, setPhoneList] = useState([]);
  const { getFieldDecorator, resetFields } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      console.log({ err });
      if (!err) {
        onSubmit(parseNewUserForm(values));
      }
    });
  };
  const emailFields = [];
  for (const email of emailIds) {
    emailFields.push(
      <Form.Item label="Email" key={email}>
        {getFieldDecorator(email, {
          rules: [
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ],
        })(
          <Input
            suffix={
              <Button
                type="link"
                shape="circle"
                icon="delete"
                onClick={() => {
                  setEmailsIds(emailIds.filter(e => e !== email));
                  resetFields(email);
                }}
              />
            }
          />,
        )}
      </Form.Item>,
    );
  }

  const phoneFields = [];
  for (const phone of phoneList) {
    phoneFields.push(
      <Form.Item label="Phone" key={phone}>
        {getFieldDecorator(phone, {})(
          <Input
            suffix={
              <Button
                type="link"
                shape="circle"
                icon="delete"
                onClick={() => {
                  setPhoneList(phoneList.filter(p => p !== phone));
                  resetFields(phone);
                }}
              />
            }
          />,
        )}
      </Form.Item>,
    );
  }
  return (
    <Card title="Create a new user" extra={extra}>
      <Form
        labelCol={{
          xs: { span: 24 },
          sm: { span: 8 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
        onSubmit={handleSubmit}
        labelAlign="left"
      >
        <Form.Item label="User Id">
          {getFieldDecorator('userId', {
            rules: [{ required: true, message: 'Please input a user Id!' }],
          })(<Input />)}
        </Form.Item>
        <Divider orientation="left">User details</Divider>
        <Form.Item label="PAN">
          {getFieldDecorator('pan', {
            rules: [{ required: true, message: 'Please enter your PAN' }],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="authContact">
          {getFieldDecorator('authContact', {
            rules: [{ required: false }],
          })(<Input type="number" />)}
        </Form.Item>
        <Form.Item label="DOB">
          {getFieldDecorator('dob', {
            rules: [{ required: false, message: 'Please enter your DOB' }],
          })(<DatePicker format="DD-MM-YYYY" />)}
        </Form.Item>
        {emailFields}
        <Form.Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 8,
              offset: 16,
            },
          }}
        >
          <Button
            type="link"
            onClick={() =>
              setEmailsIds([...emailIds, `email_${randomIdStr(4)}`])
            }
          >
            Add new email
          </Button>
        </Form.Item>
        {phoneFields}
        <Form.Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 8,
              offset: 16,
            },
          }}
        >
          <Button
            type="link"
            onClick={() =>
              setPhoneList([...phoneList, `phone_${randomIdStr(4)}`])
            }
          >
            Add new phone
          </Button>
        </Form.Item>
        <Form.Item label="Bank Name">
          {getFieldDecorator('bankName', {})(<Input />)}
        </Form.Item>{' '}
        <Form.Item label="Bank Account No">
          {getFieldDecorator('accountNumber', {})(<Input />)}
        </Form.Item>{' '}
        <Form.Item label="IFSC Code">
          {getFieldDecorator('ifscCode', {})(<Input />)}
        </Form.Item>
        <Form.Item label="Account Type">
          {getFieldDecorator('accountType', {})(
            <Radio.Group>
              <Radio.Button value="SAVINGS">Savings</Radio.Button>
              <Radio.Button value="CURRENT">Current</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label="MF Holdings JSON">
          {getFieldDecorator('mfHoldings', {
            rules: [{ validator: validateIfJson }],
          })(<TextArea autoSize />)}
        </Form.Item>
        <Divider orientation="left">Service config</Divider>
        <Form.Item label="Enable Lien Marking">
          {getFieldDecorator('isLienMarkingEnabled', {
            rules: [],
          })(<Checkbox />)}
        </Form.Item>
        <Form.Item
          wrapperCol={{
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 8,
              offset: 16,
            },
          }}
        >
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            Register
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const WrappedUserForm = Form.create({ name: 'register-las-user' })(NewUserForm);

export default WrappedUserForm;
