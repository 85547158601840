import { useEffect } from 'react';

const setsEqual = (setA, setB) =>
  setA.size === setB.size && !Array.from(setA).some(v => !setB.has(v));

const shortcuts = {
  DISCOVER: {
    combinationSet: new Set(['control', 'shift', '1']),
    action: () => {
      window.location = `${window.location.origin}/discover`;
    },
  },
  CREATE: {
    combinationSet: new Set(['control', 'shift', '@']),
    action: () => {
      window.location = `${window.location.origin}/create`;
    },
  },
  INVESTMENTS: {
    combinationSet: new Set(['control', 'shift', '3']),
    action: () => {
      window.location = `${window.location.origin}/investments`;
    },
  },
  HOLDINGS: {
    combinationSet: new Set(['control', 'shift', '4']),
    action: () => {
      window.location = `${window.location.origin}/holdings`;
    },
  },
  SIGNUP: {
    combinationSet: new Set(['control', 'shift', '5']),
    action: () => {
      window.location = `${window.location.origin}/signup`;
    },
  },
};

export const useShortcuts = () => {
  const pressedKeys = new Set();

  function onKeyPressed(event) {
    if(!event.key) return;
    pressedKeys.add(event.key.toLowerCase());
    Object.keys(shortcuts).forEach(shortcut => {
      if (setsEqual(pressedKeys, shortcuts[shortcut].combinationSet)) {
        shortcuts[shortcut].action();
      }
    });
  }

  function onKeyUp(event) {
    if(!event.key) return;
    pressedKeys.delete(event.key.toLowerCase());
  }

  function onWindowBlur() {
    pressedKeys.clear();
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPressed);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('keydown', onKeyPressed);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);
};
