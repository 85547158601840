import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  Row,
  Col,
  Statistic,
  Icon,
  Empty,
  Button,
  Collapse,
  Skeleton,
  Popover
} from 'antd'
import { getCookie } from '../../../../utils/cookieService'
import { getPendingActions } from '../../../../utils/apiService'
import { logSharedResponse } from '../../../../utils'
import ReactJson from 'react-json-view'

const { Meta } = Card
const { Panel } = Collapse

export default class Investement extends Component {
  state = {
    loading: true,
    investments: [],
    pendingActions: [],
    actionsLoading: true
  }

  componentDidMount() {
    const userID = getCookie('userID')
    window.sc.getUserInvestments().then(res => {
      logSharedResponse('User Investments', res)
      this.setState({
        investments: res,
        loading: false
      })
    })
    getPendingActions(userID).then(res => {
      this.setState({
        actionsLoading: false,
        pendingActions: res.investments || []
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.login !== prevProps.login) {
      this.setState({
        loading: true
      })
      window.sc.getUserInvestments().then(res => {
        logSharedResponse('User Investments', res)
        this.setState({
          investments: res,
          loading: false
        })
      })
    }
  }

  render() {
    const { loading, investments } = this.state
    const { login } = this.props

    if (!login) {
      return (
        <div className="container">
          <Empty description={'Login to view Investments'}>
            <Button onClick={this.props.handleLogin} className="ios-click">
              Login
            </Button>
          </Empty>
        </div>
      )
    }
    return (
      <div className="container">
        <Row gutter={8}>
          <Col xs={24} md={18}>
            <Card title="Investments" loading={loading} style={{minHeight: '269px'}}>
              <Row>
                {investments.map(investment => {
                  const { returns } = investment.investment
                  const pnl =
                    returns.realizedReturns +
                    (returns.networth - returns.unrealizedInvestment) +
                    returns.divReturns
                  return (
                    <Col
                      lg={12}
                      style={{ padding: '10px' }}
                      key={investment.investment.iscid}
                    >
                      <Link to={`/investments/${investment.investment.iscid}`}>
                        <Card>
                          <Meta
                            title={investment.investment.name}
                            // description={investment.investment.shortDescription}
                          />
                          <div className="flex-apart">
                            <Statistic
                              title="Net Worth"
                              value={returns.networth}
                              className="mt8"
                              precision={2}
                            />
                            <Statistic
                              title="Returns"
                              value={pnl}
                              className="mt8"
                              precision={2}
                              valueStyle={{ color: '#3f8600' }}
                              prefix={<Icon type="arrow-up" />}
                            />
                          </div>
                        </Card>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card title="Pending Actions" style={{minHeight: '269px'}}>
              <Skeleton title={false} loading={this.state.actionsLoading} active={true} paragraph={{
                rows: 5,
                width: '100%'
              }}>
                <Collapse>
                    {this.state.pendingActions.map(investment => (
                      <Panel header={investment.smallcaseName} key="1">
                        {
                          investment.fixAction && (
                            <Popover placement="left" title="Repair" content={<ReactJson src={{fixAction: investment.fixAction}} name={false}/>} trigger="click">
                              <Button style={{width: '100%'}}>Repair</Button>
                            </Popover>
                          )
                        }
                             {
                          investment.rebalanceAction && (
                            <Popover placement="left" title="Rebalance" content={<ReactJson src={{rebalanceAction: investment.rebalanceAction}} name={false}/>} trigger="click">
                                <Button style={{width: '100%', marginTop: '4px'}}>Rebalance</Button>
                            </Popover>
                          )
                        }
                        {
                          investment.sipAction && (
                            <Popover placement="left" title="SIP" content={<ReactJson src={{sipAction: investment.sipAction}} name={false}/>} trigger="click">
                              <Button style={{width: '100%', marginTop: '4px'}}>SIP</Button>
                            </Popover>
                          )
                        }
                        {
                          investment.subscriptionAction && (
                            <Popover placement="left" title="SIP" content={<ReactJson src={{subscriptionAction: investment.subscriptionAction}} name={false}/>} trigger="click">
                              <Button style={{width: '100%', marginTop: '4px'}}>Subscription</Button>
                            </Popover>
                          )
                        }
                        
                      </Panel>
                    ))}
                  </Collapse>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
