import React, { useState } from 'react';
import { notification, Divider, Input, Button, Tabs } from 'antd';

import {
  createNewSubscriptionAPI,
  cancelSubscriptionAPI,
  getAllSubscriptionAPI,
} from '../../../../utils/apiService';
import { getCookie } from '../../../../utils/cookieService';
import { logSharedResponse } from '../../../../utils';

import './Subscription.css';

const { TabPane } = Tabs;

export default function Subscription() {
  const [scid, updateScid] = useState({});
  const [allSubscriptionsResp, updateAllSubscriptionsResp] = useState('');
  const [newSubscriptionResp, updateNewSubscriptionResp] = useState('');
  const [cancelSubscriptionResp, updateCancelSubscriptionResp] = useState('');

  const manageSubscription = type => {
    const userID = getCookie('userID');
    if (!userID) {
      notification['error']({
        message: 'Subscription management API works for CONNECTED users only',
        description: 'Please connect the user first',
        placement: 'bottomLeft',
      });
      return;
    }
    if (type === 'fetch') {
      updateAllSubscriptionsResp('');
      getAllSubscriptionAPI(userID).then(res => {
        logSharedResponse('User Subscriptions', res);
        updateAllSubscriptionsResp(res);
      });
      return;
    }
    const _scid = scid && type && scid[type];
    if (!_scid) {
      notification['error']({
        message: 'Please enter scid',
        description: "Can't be empty",
        placement: 'bottomLeft',
      });
      return;
    }
    if (type === 'new') {
      updateNewSubscriptionResp('');
      createNewSubscriptionAPI(_scid, userID).then(res => {
        logSharedResponse('New Subscription', res);
        updateNewSubscriptionResp(res);
      });
    } else if (type === 'cancel') {
      updateCancelSubscriptionResp('');
      cancelSubscriptionAPI(_scid, userID).then(res => {
        logSharedResponse('Cancel Subscription', res);
        updateCancelSubscriptionResp(res);
      });
    }
  };

  const handleScidChange = (key, val) => {
    updateScid({ ...scid, [key]: val });
  };

  return (
    <div className="container">
      <h1 className="action-header">
        Subscription management for fee-only smallcases
      </h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Fetch all subscriptions" key="1">
          <Button
            type="primary"
            onClick={() => manageSubscription('fetch')}
            style={{ marginBottom: 16 }}
          >
            Fetch
          </Button>
          {allSubscriptionsResp && (
            <>
              <Divider orientation="left">Response</Divider>
              <pre>{JSON.stringify(allSubscriptionsResp, null, 4)}</pre>
            </>
          )}
        </TabPane>
        <TabPane tab="New subscription" key="2">
          scid:
          <Input
            size="large"
            value={scid.new}
            placeholder="GATEMO_0003"
            onChange={e => handleScidChange('new', e.target.value)}
            style={{ marginTop: 4, marginBottom: 16 }}
          />
          <Button
            type="primary"
            onClick={() => manageSubscription('new')}
            disabled={!scid.new}
          >
            Subscribe
          </Button>
          {newSubscriptionResp && (
            <>
              <Divider orientation="left">Response</Divider>
              <pre>{JSON.stringify(newSubscriptionResp, null, 4)}</pre>
            </>
          )}
        </TabPane>
        <TabPane tab="Cancel subscription" key="3">
          scid:
          <Input
            size="large"
            value={scid.cancel}
            placeholder="GATEMO_0003"
            onChange={e => handleScidChange('cancel', e.target.value)}
            style={{ marginTop: 4, marginBottom: 16 }}
          />
          <Button
            type="primary"
            disabled={!scid.cancel}
            onClick={() => manageSubscription('cancel')}
          >
            Cancel
          </Button>
          {cancelSubscriptionResp && (
            <>
              <Divider orientation="left">Response</Divider>
              <pre>{JSON.stringify(cancelSubscriptionResp, null, 4)}</pre>
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}
