import React from 'react';
import { notification } from 'antd';
import ResponseNotification from '../components/ResponseNotification/ResponseNotification';


export function logSharedResponse(type, data) {
  let response = data;  
  if (typeof response !== 'string') {
    response = JSON.stringify(response, null, 4);
  }
  notification.success({
    message: 'Operation was Successful!',
    description: <ResponseNotification type={type} data={data} />,
    duration: 15,
    placement: 'bottomRight',
  });
  console.log(`👇 ${type} 👇\n\n${response}`);
}

export function errorWithDetails(description, error) {
  const errObj = {
    errorName: error.name,
    code: error.code,
    message: error.message,
    data: error.data,
  };
  return (
    <div>
      {description}
      <details>
        <pre>{JSON.stringify(errObj, null, 2)}</pre>
      </details>
    </div>
  );
}

export function notifySdkError(error) {
  if (error.name === 'GatewayError'|| error.name === 'ScLoanError') {
    notification.error({
      message: 'Error received!',
      description: errorWithDetails('Gateway SDK returned an error!', error),
      duration: 15,
      placement: 'bottomRight',
    });
  }
}

export function handleErrorMessage(err) {
  switch (err.message) {
    case 'invalid_transaction':
      notification.error({
        message: 'Invalid Transaction',
        description: errorWithDetails('You must be having multiple instances of same stock in your order.' ,err),
        duration: 5,
        placement: 'topRight'
      });
      break;
    case 'invalid_user_data':
      notification.error({
        message: 'Login Error',
        description: errorWithDetails('You must login before trying to place an order' ,err),
        duration: 5,
        placement: 'bottomRight'
      })
      break;
    case 'market_closed':
      notification.error({
        message: 'Markets Closed',
        description: errorWithDetails('Markets are closed right now, please try again on the next market session' ,err),
        duration: 5,
        placement: 'topRight'
      })
      break;
    case 'no_broker':
      notification.error({
        message: 'No Broker 😞',
        description: errorWithDetails('Doesn\'t have a broker account or broker not supported yet' ,err),
        duration: 5,
        placement: 'topRight'
      })
      break;
    default:
      if (err.name === 'GatewayError' || err.name === 'ScLoanError') {
        notifySdkError(err);
      } else {
        notification.error({
          message: 'Uh oh!',
          description: 'Something went wrong while trying to do that !',
          duration: 1,
          placement: 'bottomRight'
        })
      }
  }
}

export function randomIdStr(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
