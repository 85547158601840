export const parseToLocaleDateString = dateString => {
  try {
    return dateString
      ? new Date(dateString).toLocaleDateString(undefined, {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : '';
  } catch (e) {
    return '';
  }
};
