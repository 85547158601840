import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button, Table, Divider, notification, Statistic, Switch, Menu, Dropdown, Popover, Tag } from 'antd'
// import { RedoOutlined } from '@ant-design/icons';
import { transactionAPI, holdingsAPI } from '../../../../utils/apiService'
import { getCookie } from '../../../../utils/cookieService'
import { logSharedResponse, notifySdkError } from '../../../../utils'
import HoldingsV2Table from './HoldingsV2Table'
import './Holdings.css';

const HOLDINGS_IMPORT = 'HOLDINGS_IMPORT'
const FETCH_FUNDS = 'FETCH_FUNDS'

const mfAssetConfig = {
    mfHoldings: true,
}
function triggerOrder(includeMf, holdingsV2) {
    transactionAPI(getCookie('userID'), HOLDINGS_IMPORT, includeMf && mfAssetConfig, null, holdingsV2 && 'v2')
        .then(({ transactionId }) => window.sc.triggerTransaction({ transactionId }))
        .then(res => logSharedResponse('Holdings Response', res))
        .catch(err => {
            notifySdkError(err);
            throw err;
        });
}

function fetchUserHoldings(setHoldings, setStatusCode, includeMf, holdingsV2) {
    const id = getCookie('userID');
    if(!id){
        notification.warn({
            message: 'Invalid operation',
            description: 'Operation not avalable for guest user',
            duration: 2,
            placement: 'bottomRight',
        });
        return;
    }
    holdingsAPI(id, includeMf, holdingsV2 && 'v2')
        .then(({ data, statusCode }) => {
            if(data.success){
                setStatusCode(statusCode)
                setHoldings(data);
            } else {
                notification.error({
                    message: data.errorType,
                    description: `${statusCode}: ${data.errors[0]}`,
                    duration: 2,
                    placement: 'topRight'
                  });

            }
        })
}

function fetchUserFunds(updateFunds) {
    const id = getCookie('userID');
    if(!id){
        notification.warn({
            message: 'Invalid operation',
            description: 'Operation not avalable for guest user',
            duration: 2,
            placement: 'bottomRight',
        });
        return;
    }
	return transactionAPI(id, FETCH_FUNDS)
		.then(({ transactionId }) => window.sc.triggerTransaction({
			transactionId,
        }))
        .then(res => {
            logSharedResponse('Fetch Funds', res)
            updateFunds(res.funds)
        })
        .catch(err => {
            notifySdkError(err);
            throw err;
        });
}


const infoDatasource = [
    {
        title: 'Status Code',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Updating',
        dataIndex: 'updating',
        key: 'updating'
    },
    {
        title: 'Last Update',
        dataIndex: 'lastUpdate',
        key: 'lastUpdate'
    },
    {
        title: 'Snapshot Date',
        dataIndex: 'snapshotDate',
        key: 'snapshotDate'
    },
    {
        title: 'smallcase Auth ID',
        dataIndex: 'smallcaseAuthId',
        key: 'smallcaseAuthId'
    },
];


const getinfoData = (statusCode, holdings) =>{
    return [{
        status: statusCode,
        updating: `${holdings.data.updating}`,
        lastUpdate: holdings.data.lastUpdate,
        snapshotDate: holdings.data.snapshotDate,
        smallcaseAuthId: holdings.data.smallcaseAuthId,
    }];
}

const getTotalStockCount = holdingsData => {
    if(Array.isArray(holdingsData.securities)){
        return holdingsData.securities.length
    }
    if(Array.isArray(holdingsData.securities.holdings)){
        return holdingsData.securities.holdings.length;
    }
    return 0;
}

const scDatasource = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'scid',
            dataIndex: 'scid',
            key: 'scid'
        },
        {
            title: 'Current Value',
            dataIndex: 'stats.currentValue',
            key: 'scid',
        },
        {
            title: 'Total Returns',
            dataIndex: 'stats.totalReturns',
            key: 'shortDescription'
        },
    ];

const scPrivateDatasource = [
    {
        title: 'Current Value',
        dataIndex: 'currentValue',
        key: 'currentValue'
    },
    {
        title: 'Total Returns',
        dataIndex: 'totalReturns',
        key: 'totalReturns'
    },
]

const sstDatasource = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Exchange',
        dataIndex: 'exchange',
        key: 'exchange'
    },
    {
        title: 'Shares',
        dataIndex: 'shares',
        key: 'shares'
    },
    {
        title: 'Avg. Price',
        dataIndex: 'averagePrice',
        key: 'averagePrice'
    },
];

const mfDataSource = [
    {
        title: 'Folio',
        dataIndex: 'folio',
        key: 'folio'
    },
    {
        title: 'Fund',
        dataIndex: 'fund',
        key: 'fund'
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
        key: 'isin'
    },
    {
        title: 'P&L',
        dataIndex: 'pnl',
        key: 'pnl'
    },
    {
        title: 'Avg. Price',
        dataIndex: 'averagePrice',
        key: 'averagePrice'
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity'
    },
    {
        title: 'Last Price',
        dataIndex: 'lastPrice',
        key: 'lastPrice'
    },
    {
        title: 'Last Price Date',
        dataIndex: 'lastPriceDate',
        key: 'lastPriceDate'
    },
]

const expandedRow = (record, index, indent, expanded) =>{
    return <div>
        <h3 className='inner-headers'>Constituents</h3>
        <Table
            bordered
            style={{ width:'50%'}}
            pagination={false}
            size='small'
            dataSource={record.constituents}
            columns={[
                {
                    title: 'Ticker',
                    dataIndex: 'ticker',
                    key: 'ticker'
                },
                {
                    title: 'Shares',
                    dataIndex: 'shares',
                    key: 'shares'
                },
            ]}
        />
    </div>
}

const SettingsCta = ({ overlay }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [badgeVisible, setBadgeVisible] = useState(true);
    const clickHandler = (isVisible)=>{
        setDropdownVisible(isVisible);
        if(badgeVisible) setBadgeVisible(false);
    }
    return (
      <Dropdown
        overlay={overlay}
        onVisibleChange={clickHandler}
        visible={dropdownVisible}
        trigger={['click']}
      >
        <Popover
          content={<span><Tag color="blue">new</Tag> Holdings Import v2</span>}
          trigger="click"
          placement="topRight"
          visible={badgeVisible}
        >
            <Button icon="setting" shape="circle" type="link" />
        </Popover>
      </Dropdown>
    );
  };


export default function Holdings() {
    const [statusCode, setStatusCode] = useState(200);
    const [holdings, setHoldings] = useState({});
    const [funds, setFunds] = useState(null);
    const [includeMf, setIncludeMf] = useState(false);
    const [holdingsV2, setHoldingsV2] = useState(false);

    const fetchFunds = useCallback(() => {
        fetchUserFunds(setFunds);
    }, [])

    // reset holdings when config changes
    useEffect(()=>{
        setHoldings({})
    }, [includeMf, holdingsV2]);
    /*
     *  Private sc holings is an array containing sc holdings, for first party integrations
     *  and for others integrations, it is an object containing stats
     */
    const privateSmallcases = holdings.data?.smallcases?.private;

    const mfHoldings = holdings.data?.mutualFunds?.holdings;

    const onToggleMFimport = (flag)=> {
        setIncludeMf(flag);
    }
    const onToggleFormat = (flag)=> {
        setHoldingsV2(flag);
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <span className="settings-toggle">Include MF<Switch className="ml8" checked={includeMf} size="small" onChange={onToggleMFimport} /></span>
            </Menu.Item>
            <Menu.Item>
                <span className="settings-toggle">V2 format<Switch className="ml8" checked={holdingsV2} size="small" onChange={onToggleFormat} /></span>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="container">
            <Card>
                <Statistic
                    title={
                        <p>Funds <Button 
                                type="primary"
                                shape="circle"
                                icon="reload"
                                size="small"
                                onClick={fetchFunds}
                            />
                        </p>
                    } value={funds !== null ? funds : 'Funds not available'} 
                />
            </Card>
            <Card
                title="Holdings"
                extra={
                    <div style={{ display: 'flex'}}>
                        <Button 
                        type="primary"
                        onClick={()=>triggerOrder(includeMf, holdingsV2)}
                        className="ios-click mr8"
                        title="synchronise holdings snapshot with broker">
                            Sync Holdings
                        </Button>
                        <Button
                        type="secondary"
                        onClick={() => fetchUserHoldings(setHoldings, setStatusCode, includeMf, holdingsV2)}
                        title="fetch latest holdings snapshot from gateway API"> 
                            Fetch Holdings
                        </Button>
                        <SettingsCta overlay={menu} />
                    </div>
                }
                className="mt8"
            >
                {Object.keys(holdings).length > 0 &&  <h1>Fetched !</h1>}

                {Object.keys(holdings).length > 0 &&
                    <Card>

                        <Divider orientation="left">
                            <h2 className='statusHeaders'>Status</h2>
                        </Divider>
                        <Table
                            bordered
                            dataSource={getinfoData(statusCode, holdings)}
                            columns={infoDatasource}
                            pagination={false}
                        />

                        <Divider orientation="left" style={{margin: '60px 0 0px'}}>
                            <h2 className='statusHeaders'>Smallcases</h2>
                        </Divider>

                            <h3 className='inner-headers'>Public ({holdings.data.smallcases.public.length})</h3>
                            <Table
                                bordered
                                dataSource={holdings.data.smallcases.public}
                                expandedRowRender={expandedRow}
                                columns={scDatasource}
                            />

                            <h3 className='inner-headers'>
                                Private {Array.isArray(privateSmallcases) ? `(${privateSmallcases.length})` : ''}
                            </h3>
                            <Table
                                bordered
                                pagination={false}
                                dataSource={Array.isArray(privateSmallcases) ? privateSmallcases : [
                                    {
                                        currentValue: privateSmallcases?.stats?.currentValue,
                                        totalReturns: privateSmallcases?.stats?.totalReturns,
                                    }
                                ]}
                                columns={Array.isArray(privateSmallcases) ? scDatasource : scPrivateDatasource}
                            />

                        <Divider orientation="left" style={{margin: '60px 0 0px'}}>
                            <h2 className='statusHeaders'>Stocks ({getTotalStockCount(holdings.data)})</h2>
                        </Divider>
                        {holdingsV2 && Array.isArray(holdings.data.securities) ? (
                            <HoldingsV2Table securities={holdings.data.securities} />
                            ) : (
                            <Table
                                bordered
                                dataSource={holdings.data.securities.holdings}
                                columns={sstDatasource}
                            />
                        )}

                        {mfHoldings  && (<>
                            <Divider orientation="left" style={{margin: '60px 0 0px'}}>
                                <h2 className='statusHeaders'>Mutual Funds ({mfHoldings.length})</h2>
                            </Divider>
                            <Table
                                bordered
                                dataSource={mfHoldings}
                                columns={mfDataSource}
                            />
                        </>)}
                    </Card>
                }
            </Card>

            
        </div>
    )
}