import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Icon } from 'antd';

const deriveTimeSeries = historicalData => {
  const benchmarkData = historicalData.benchmark.points;
  const smallcaseData = historicalData.smallcase.points;
  return benchmarkData.map((benchmark, i) => ({
    xLabel: new Date(benchmark.date).toLocaleDateString('en-IN', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
    }),
    benchmarkValue: benchmark.value,
    smallcaseValue: smallcaseData[i]?.value,
  }));
};

const dataStatus = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

const HistoricalPlot = ({ scid, benchmark }) => {
  const [status, setStatus] = useState(dataStatus.LOADING);
  const [historicalData, setHistoricalData] = useState();
  const { index, msg } = benchmark;

  useEffect(() => {
    window.sc
      .getHistorical({ scid, benchmarkId: index })
      .then(data => {
        setHistoricalData(data);
        setStatus(dataStatus.SUCCESS);
      })
      .catch(err => {
        setStatus(dataStatus.FAILURE);
      });
  }, []);
  if (status === dataStatus.LOADING) return <p><Icon type="loading" /> Loading... </p>;
  if (status === dataStatus.FAILURE) return 'Data unavailable';
  return (
    <div>
      <p>{msg}</p>
      <LineChart
        width={500}
        height={300}
        data={deriveTimeSeries(historicalData)}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="xLabel" hide />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="smallcaseValue"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name={scid}
        />
        <Line
          type="monotone"
          dataKey="benchmarkValue"
          stroke="#82ca9d"
          name={index}
        />
      </LineChart>
    </div>
  );
};

export default HistoricalPlot;
