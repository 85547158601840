import { Table, Tag } from 'antd';
import React from 'react';

export const columnsType = {
  recent: [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: date => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: data => {
        if (data === 'SUCCESS') return <Tag color="green">SUCCESS</Tag>;
        else if (data === 'FAILED') return <Tag color="red">{data}</Tag>;
      },
    },
  ],
  upcoming: [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: data => {
        const date = new Date(data);
        return date.toLocaleDateString();
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },

    {
      title: 'Account Number',
      dataIndex: 'linkedBankDetails.accountNumber',
      key: 'accountNumber',
      width: 150,
    },
    {
      title: 'Bank ',
      dataIndex: 'linkedBankDetails.bankName',
      key: 'bankName',
      width: 150,
    },
  ],
  mfs: [
    {
      title: 'ISIN:Folio',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'MF Name',
      dataIndex: 'scripName',
      key: 'scripName',
    },
    {
      title: 'Units Pledged',
      dataIndex: 'units',
      key: 'units',
    },
    {
      title: 'Credit Limit',
      dataIndex: 'creditLimit',
      key: 'creditLimit',
    },
  ],
};

const DataTable = ({ details, showPagination = true, colType }) => {
  return (
    <Table
      scroll={{ x: 'max-content' }}
      dataSource={details}
      columns={colType}
      pagination={showPagination}
    />
  );
};

export default DataTable;
