import React from 'react';
import { Card, Table } from 'antd';

const { Column, ColumnGroup } = Table;

const MfTransactionTable = ({ transactions }) => {
  return (
    <Card title="Transactions">
      {Array.isArray(transactions) ? (
        <Table bordered dataSource={transactions} scroll={{ x: 2000 }}>
          <Column title="ISIN" dataIndex="isin" key="isin" />
          <Column
            title="Folio number"
            dataIndex="folioNumber"
            key="folioNumber"
          />
          <Column title="Scheme Code" dataIndex='schemeCode' key='schemeCode' />
          <Column title="Scheme" dataIndex="scheme" key="scheme" />
          <Column title="AMC" dataIndex="amc" key="amc" />
          <Column title="AMC Code" dataIndex="amcCode" key="amcCode" />
          <Column
            title="Transaction Date"
            dataIndex="transactionDate"
            key="transactionDate"
          />
          <Column title="Posted Date" dataIndex="postedDate" key="postedDate" />
          <Column
            title="Txn description"
            dataIndex="trxnDescription"
            key="trxnDescription"
          />
          <Column title="Txn amount" dataIndex="trxnAmount" key="trxnAmount" />
          <Column title="Txn units" dataIndex="trxnUnits" key="trxnUnits" />
          <Column
            title="Purchase Price"
            dataIndex="purchasePrice"
            key="purchasePrice"
          />
          <Column title="Stamp Duty" dataIndex="stampDuty" key="stampDuty" />
          <Column title="Total tax" dataIndex="totalTax" key="totalTax" />
          <Column
            title="Txn Mode"
            dataIndex="transactionMode"
            key="transactionMode"
          />
          <Column title="Cash Flow" dataIndex="cashFlow" key="cashFlow" />
          <ColumnGroup title="Analytics" key="analytics" >
            <Column title="Exited" dataIndex='analytics.exited' key='analytics.exited' render={(text) => String(text)} />
            <Column title='Transaction Type' dataIndex='analytics.transactionType' key='analytics.transactionType' />
            <Column title='Transaction Status' dataIndex='analytics.transactionStatus' key='analytics.transactionStatus' />
            <Column title='Transaction Description' dataIndex='analytics.transactionDescription' key='analytics.transactionDescription' />
          </ColumnGroup>
        </Table>
      ) : (
        'transactions not found'
      )}
    </Card>
  );
};

export default MfTransactionTable;
