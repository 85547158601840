import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { logSharedResponse } from '../../../../utils';
import './Smallplug.css';


export default function Smallplug() {
    const [path, setPath] = useState(undefined);
    const [params, setParams] = useState(undefined);
    const openSmallplug = () => {
        window.sc.openSmallplug(path && {path, params}).then((res) => {
            logSharedResponse('Smallplug Response', res);
        });
    }

    return (
        <div className="smallplug-container">
            <Input
                placeholder="Enter Path"
                value={path}
                onChange={e => setPath(e.target.value)}
            />
            <Input
                className="params-input"
                placeholder="Enter Params"
                value={params}
                onChange={e => setParams(e.target.value)}
            />
            <Button className="smallplug-btn" type="button" onClick={openSmallplug}>Open Smallplug</Button>
        </div>
    );
}