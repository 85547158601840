import React, { Component } from "react"
import { Button, Card, Avatar, Row, Col, Statistic, Icon, Table, Empty, notification } from 'antd'
import { withRouter } from 'react-router'
import { transactionAPI } from "../../../../utils/apiService"
import { getCookie } from "../../../../utils/cookieService"
import { logSharedResponse, notifySdkError } from "../../../../utils"
const { Meta } = Card

const TRANSACTION = 'TRANSACTION';
const SIP_SETUP = 'SIP_SETUP';
const CANCEL_AMO = 'CANCEL_AMO';

const isAmoAndCancelled = (successData) => {
	const { variety, status } = successData.batches[0];
	return variety === 'amo' && status === 'CANCELLED';
}

class InvestmentDetails extends Component {
	state = {
		loading: true,
		investment: null
	}

	componentDidMount() {
		this.fetchUserInvestmentsDetails()
	}

	componentDidUpdate(prevProps) {
		if (this.props.login !== prevProps.login) {
			this.setState({
				loading: true,
			})
			this.fetchUserInvestmentsDetails()
		}
	}

	fetchUserInvestmentsDetails = () => window.sc.getUserInvestmentDetails({
			iscid: this.props.match.params.iscid
		})
		.then(investments => {
			logSharedResponse('Investment Details', investments);
			if (investments.length === 1) {
				return investments[0]
			}
			return Promise.reject('Iscid Not Found!')
		})
		.then(investment => this.setState({
			investment,
			loading: false,
		}))
		.catch(err => {
			notifySdkError(err);
			throw err;
		});

	triggerOrder = (type) => {
		let fetchTransaction;
		if (type === 'sip_setup') {
			 fetchTransaction = transactionAPI(getCookie('userID'), SIP_SETUP, {
				iscid: this.state.investment?.investment?.iscid,
			})
		} else if (type === 'cancel_amo') {
			fetchTransaction = transactionAPI(getCookie('userID'), CANCEL_AMO, {
				iscid: this.state.investment?.investment?.iscid
			})
		} else {
			fetchTransaction = transactionAPI(getCookie('userID'), TRANSACTION, {
				type,
				iscid: this.state.investment?.investment?.iscid,
				scid: this.state.investment?.investment?.scid,
			})
		}
		fetchTransaction
			.then(({ transactionId }) => {
				this.setState({
				transactionId,
				})
				return transactionId
			})
			.then(transactionId => window.sc.triggerTransaction({
				transactionId,
			}))
			.catch(err => {
				notifySdkError(err);
				if (err.message === 'user_cancelled') {
					this.fetchUserInvestmentsDetails()
				} else if (err.message === 'smallcase_archived') {
					this.props.history.replace('/investments')
				}
				throw err;
			})
			.then((res) => {
				logSharedResponse("Order Response", res);
				if (type === 'exit') {
					this.props.history.replace('/investments')
					return
				}
				else if (type === 'cancel_amo' && isAmoAndCancelled(res)) {
					this.props.history.replace('/investments');
					return;
				}
			})
			.then((res) => {
				this.fetchUserInvestmentsDetails()
			})
	}
	

	archive = () => {
		window.sc.markArchived({
			iscid: this.state.investment?.investment?.iscid,
		})
		.then((res) => {
			logSharedResponse("Order Response", res);
			if (res.smallcaseDeleted) {
				this.props.history.replace('/investments')
				return
			}
			this.fetchUserInvestmentsDetails()
		})
		.catch(err => {
			notifySdkError(err);
			throw err;
		})
	}


	render() {
		const {
			login
	  } = this.props

	  if (!login) {
			return (
				 <div className="container">
					  <Empty
							description={'Login to view Investments'}
					  >
							<Button
								onClick={this.props.handleLogin}
								className="ios-click"
							>
								Login
							</Button>
					  </Empty>
				 </div>
			)
	  }

		const {
			investment,
			loading,
		} = this.state
		let returns = null, pnl = null
		if (investment) {
			returns = investment.investment.returns

			pnl = returns.realizedReturns +
			(returns.networth - returns.unrealizedInvestment) +
			returns.divReturns
		}
		
		return (
			<div className="container">
				<Row>
					<Col xs={24}>
						<Card title={'Investment Details'} loading={loading}>
						{
							!loading && (
								<div className="flex-verticle">
									<Meta
										title={investment.investment.name}
										description={investment.investment.shortDescription}
										avatar={<Avatar
											src={investment.investment.imageUrl}
											shape="square"
											size="large"
										/>}
									/>
									<div className="button">
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8"
											onClick={() => this.triggerOrder('repair')}
										>Repair</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8"
											onClick={this.archive}
										>Archive</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8"
											onClick={() => this.triggerOrder('investmore')}
										>Invest More</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('manage')}
										>Manage</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('exit')}
										>Exit</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('cancel_amo')}
											disabled={!investment.investment.flags.amoPending}
										>Cancel AMO</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('sip')}
										>SIP Order</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('rebalance')}
										>Rebalance</Button>
										<Button
											type="primary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('sip_setup')}
										>Sip Setup</Button>
										<Button
											type="secondary"
											size={'large'}
											className="mt8 mr8 ios-click"
											onClick={() => this.triggerOrder('dummy')}
										>Place dummy order</Button>
									</div>
								</div>
							)
						}
					</Card>
						{
							!loading && (
									<Row>
										<Col md={9} xs={24} style={{ marginTop: '15px'}}>
											{
												<Card title="Investment Details" style={{ minHeight: '250px'}}>
													<Meta
														title={investment.investment.name}
													/>
													<div className="flex-apart">
														<Statistic
															title="Net Worth"
															value={returns.networth}
															precision={2}
															className="mt8"
														/>
														<Statistic
															title="Returns"
															value={pnl}
															className="mt8"
															precision={2}
															valueStyle={{ color: '#3f8600' }}
															prefix={<Icon type="arrow-up" />}
														/>
													</div>
												</Card>
											}

										</Col>
										<Col md={15} xs={24} style={{ marginTop: '15px' }}>
											<Card loading={loading} title={'Current Stocks'} style={{ minHeight: '250px'}}>
												{
                          (
                            investment.investment.currentConfig &&
                            Array.isArray(investment.investment.currentConfig.constituents)
                          ) &&
													<Table
														columns={[
															{
																title: 'Stock Name',
																dataIndex: 'stockName',
																key: 'stockName',
															}, {
																title: 'Shares',
																dataIndex: 'shares',
																key: 'shares',
															}, {
																title: 'Avg. Price',
																dataIndex: 'averagePrice',
																key: 'averagePrice',
															}
														]}
														dataSource={[
															...investment.investment.currentConfig.constituents
														]}
														pagination={false}
														rowKey={'stockName'}
														size={'small'}
														scroll={{ x: 2}}
													/>
												}
											</Card>
										</Col>
									</Row>
							)
						}
					</Col>
				</Row>
			</div>
		)
	}
}

export default withRouter(InvestmentDetails)