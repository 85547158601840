import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Skeleton } from 'antd'
import { logSharedResponse } from '../../../../utils'
const { Meta } = Card

function stopLoading(setLoading) {
    setTimeout(() => {
        setLoading(false)
    }, 4000)
}

function fetchSmallcases() {
    return window.sc.getSmallcases()
        .then(res => res.smallcases)
}



const Discover = props => {
    const [loading, setLoading ] = useState(true)
    const [smallcases, loadSmallcases ] = useState([])
    useEffect(() => {
        fetchSmallcases()
            .then(smallcasesData => {
                logSharedResponse('smallcasesData', smallcasesData);
                loadSmallcases([...smallcasesData])
                setLoading(false)
            })
    }, [])
    stopLoading(setLoading)
    return (
        <div className="container">
            <Row>
                {
                    loading && [1,2,3,4].map(a => (
                        <Col span={6} className="flex-center" key={a} sm={12}>
                                <Card
                                    hoverable
                                    style={{ minHeight: '333px', width: '100%'}}
                                    loading={loading}
                                >
                                    <Skeleton active />
                                </Card>
                        </Col>)
                    )
                } {
                    !loading && smallcases.map(smallcase => (
                        <Col className="flex-center" key={smallcase._id} sm={{span: 12, offset: 0}} xs={{span: 20, offset: 2}} md={12} lg={6} style={{ marginBottom: '10px'}}>
                            <Link to={`/profile/${smallcase.scid}`} className="ios-click">
                                <Card
                                    hoverable
                                    style={{ width: '240px'}}
                                    cover={<img src={smallcase.imageUrl} style={{ padding: 20, width: 240, height: 240, objectFit: 'contain'}} alt="smallcase" onError={(e) => {
                                        const { target: image } = e;
                                        if (image.src !== 'https://cimg.smallcase.com/adhoc.png') {
                                            image.src = 'https://cimg.smallcase.com/adhoc.png';
                                        }
                                    }}/>}
                                >
                                    <Meta 
                                        title={smallcase.info.name}
                                    />
                                </Card>
                            </Link>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default Discover