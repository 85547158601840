/* global SCDK_URL */
/* global LAMF_SDK_URL */
/* global GATEWAY_NAME */
import React, { Fragment, useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Layout, Menu, Button, Modal, Input } from 'antd';
import qs from 'query-string';
import Discover from './components/Discover';
import Profile from './components/Profile';
import Investments from './components/Investments';
import InvestmentDetails from './components/InvestmentDetails';
import { loginAPI, connectAPI, transactionAPI } from '../../utils/apiService';
import { setCookie, getCookie } from '../../utils/cookieService';
import Create from './components/Create';
import Holdings from './components/Holdings';
import Postback from './components/Postback';
import HistoricalOrders from './components/HistoricalOrders';
import Signup from './components/Signup';
import Subscription from './components/Subscription';
import Smallplug from './components/Smallplug';
import { logSharedResponse, notifySdkError } from '../../utils';
import { useShortcuts } from '../../hooks/useShortCuts';
import MutualFunds from './components/MutualFunds';
import MfAccessForm from './components/MutualFunds/MFAccessForm';
import Reconciliation from './components/Reconciliation';
import Las from './components/Las';
import MfTransactions from './components/MfTransactions/MfTransactions';
import { fetchSdkType, sdkTypes } from '../../utils/sdk';
import { creditProductType } from './components/Las/Las';

const CONNECTED = 'CONNECTED';
const UNCONNECTED = 'UNCONNECTED';

const CONNECT = 'CONNECT';

const { Sider, Content } = Layout;

const queryParams = qs.parse(window.location.search);
const amoDisabled = queryParams.amo === 'false';

function login(updateStatus, id) {
  // Delete Old User Data
  setCookie('userID', undefined, -1);
  setCookie('userData', undefined, -1);
  // Call Login API to get userData
  return loginAPI(id).then(({ connected, smallcaseAuthToken: userData }) => {
    id && setCookie('userID', id, 7);
    userData && setCookie('userData', userData, 7);
    if (connected) {
      updateStatus(CONNECTED);
    } else {
      updateStatus(UNCONNECTED);
    }
    return window.sc
      .init({ userData })
      .then(() => connected)
      .catch(err => {
        notifySdkError(err);
        throw err;
      });
  });
}

function logout() {
  window.sc
    .brokerLogout()
    .then(res => logSharedResponse('Logout Response', res))
    .catch(err => {
      notifySdkError(err);
      throw err;
    });
}

function connect(id, updateStatus) {
  return transactionAPI(id, CONNECT)
    .then(({ transactionId }) =>
      window.sc.triggerTransaction({
        transactionId,
      }),
    )
    .then(response => {
      logSharedResponse('Connect Response', response);
      return connectAPI(id, response.smallcaseAuthToken);
    })
    .then(() => login(updateStatus, id))
    .catch(err => {
      notifySdkError(err);
      throw err;
    });
}

const betaSdkUrl = 'https://gateway.smallcase.com/scdk/beta/scdk.js';

const RootRouter = props => {
  const [status, updateStatus] = useState(UNCONNECTED);
  const [showModal, changeModalState] = useState(false);
  const [email, updateEmail] = useState('');
  const [isSMT, updateApp] = useState(false);
  const [hasMfAccess, setHasMfAccess] = useState(false);
  const userId = getCookie('userID') || undefined;
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    loginAPI(userId).then(({ connected, smallcaseAuthToken: userData }) => {
      const sdkType = fetchSdkType();
      let sdkUrl = SCDK_URL;
      if (sdkType === sdkTypes.BETA) sdkUrl = betaSdkUrl;
      if (sdkType === sdkTypes.LAMF) sdkUrl = LAMF_SDK_URL;
      const scDKPromise = new Promise((resolve, reject) => {
        let script = document.createElement('script');
        document.body.appendChild(script);
        script.onload = resolve;
        script.type = 'text/javascript';
        script.src = sdkUrl;
      });
      scDKPromise.then(() => {
        if (window.scDK) {
          window.sc = new window.scDK({
            gateway: GATEWAY_NAME,
            userData,
            config: {
              amo: !amoDisabled,
            },
          });
        }
        if (window.ScLoan)
          window.las = new window.ScLoan({ gatewayName: GATEWAY_NAME });

        setIsReady(true);
      });
      if (connected) {
        return updateStatus(CONNECTED);
      }
    });
  }, []);

  useEffect(() => {
    if (status === CONNECTED && showModal) {
      changeModalState(false);
    }
  }, [status]);

  useEffect(() => {
    const paths = ['discover', 'investments', 'profile', 'subscriptions'];
    const currPathname = props.location.pathname;
    if (paths.find(path => currPathname.match(path))) {
      updateApp(true);
    }
  }, [props.location.pathname]);

  useShortcuts();

  const shouldShowInSMT = isSMT;
  const shouldShowInSST = !isSMT;
  if (isReady) {
    return (
      <Layout style={{ minHeight: '100%' }}>
        <Sider collapsedWidth="0" breakpoint="lg" style={{ zIndex: 100 }}>
          <div className="logo">
            <img
              src="https://assets.smallcase.com/gateway/smartInvesting/smart-invest.svg"
              alt=""
              className="nav-link ios-click"
            />
          </div>
          <Menu
            theme="dark"
            mode="vertical"
            selectedKeys={[props.location.pathname]}
            style={{ lineHeight: '64px' }}
          >
            {shouldShowInSMT && (
              <Menu.Item>
                <Link className="nav-link ios-click" to={'/discover'}>
                  {'Discover'}
                </Link>
              </Menu.Item>
            )}
            {shouldShowInSST && (
              <Menu.Item>
                <Link className="nav-link ios-click" to={'/create'}>
                  {'Create'}
                </Link>
              </Menu.Item>
            )}
            {shouldShowInSST && (
              <Menu.Item>
                <Link className="nav-link ios-click" to={'/mf-transaction'}>
                  {'MF Transactions'}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/holdings'}>
                {'Holdings / Funds'}
              </Link>
            </Menu.Item>
            {shouldShowInSMT && status === 'CONNECTED' && (
              <Menu.Item>
                <Link className="nav-link" to={'/investments'}>
                  {'Investment'}
                </Link>
              </Menu.Item>
            )}
            {shouldShowInSMT && status === 'CONNECTED' && (
              <Menu.Item>
                <Link className="nav-link" to={'/subscriptions'}>
                  {'Subscriptions'}
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/postback'}>
                {'Postback'}
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/historicalOrders'}>
                {'Historical Orders'}
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link className="nav-link ios-click" to={'/signup'}>
                {'Signup'}
              </Link>
            </Menu.Item>
            {shouldShowInSMT && (
              <Menu.Item>
                <Link className="nav-link ios-click" to={'/smallplug'}>
                  {'Smallplug'}
                </Link>
              </Menu.Item>
            )}
          </Menu>

          <Button
            className="mt8 ios-click full-width"
            onClick={() => {
              if (status === UNCONNECTED) {
                return changeModalState(true);
              }
              // Logout if the user is already connected
              login(updateStatus, null);
            }}
          >
            {status === UNCONNECTED && 'Connect'}{' '}
            {status === CONNECTED && 'Logout'}
          </Button>

          {status === CONNECTED && (
            <Button
              size="small"
              type="link"
              className="ios-click fw"
              onClick={() => {
                logout();
              }}
            >
              smallcase Platform Logout
            </Button>
          )}
        </Sider>
        <Content>
          <Fragment>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect from="/" to="/create" />}
              />
              <Route exact path={'/discover'} component={Discover} />
              <Route
                exact
                path={'/investments'}
                render={props => (
                  <Investments
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                  />
                )}
              />
              <Route
                path="/reconcile"
                render={props => (
                  <Reconciliation loggedIn={status === CONNECTED} />
                )}
              />
              <Route
                path="/profile/:scid"
                render={props => (
                  <Profile
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                  />
                )}
              />
              <Route
                path="/investments/:iscid"
                render={props => (
                  <InvestmentDetails
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                  />
                )}
              />
              <Route
                path="/create"
                render={props => (
                  <Create
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                    email={email}
                  />
                )}
              />
              <Route
                path="/mf-transaction"
                render={props => (
                  <MfTransactions
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                    email={email}
                  />
                )}
              />
              <Route
                path="/holdings"
                render={props => (
                  <Holdings
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                    email={email}
                  />
                )}
              />
              <Route
                path="/historicalOrders"
                render={props => (
                  <HistoricalOrders
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                    email={email}
                  />
                )}
              />
              <Route
                path="/postback"
                render={props => (
                  <Postback
                    {...props}
                    login={status === CONNECTED}
                    handleLogin={() => {}}
                    email={email}
                  />
                )}
              />
              <Route path="/signup" render={props => <Signup {...props} />} />
              <Route
                path="/subscriptions"
                render={props => <Subscription {...props} />}
              />
              <Route path="/smallplug" render={props => <Smallplug />} />
              <Route
                path="/mutualfunds"
                render={() =>
                  hasMfAccess ? (
                    <MutualFunds />
                  ) : (
                    <MfAccessForm onVerifyToken={setHasMfAccess} />
                  )
                }
              />
              <Route
                path="/las"
                render={props => (
                  <Las {...props} productType={creditProductType.LAMF} />
                )}
              />
              <Route
                path="/pl"
                render={props => (
                  <Las {...props} productType={creditProductType.PL} />
                )}
              />
            </Switch>
          </Fragment>
        </Content>
        <Modal
          title="Connect"
          visible={showModal}
          onCancel={() => changeModalState(false)}
          cancelText="Close"
          footer={null}
        >
          <Input
            placeholder="User ID / Email"
            value={email}
            onChange={evt => {
              updateEmail(evt.target.value.trim());
            }}
            size="large"
          />
          <Button
            className="mt8 mr8 ios-click"
            onClick={() => {
              if (email !== '') {
                login(updateStatus, email).then(
                  isConnected =>
                    !isConnected &&
                    connect(
                      email,
                      updateStatus,
                    ),
                );
              }
            }}
          >
            Submit
          </Button>
        </Modal>
      </Layout>
    );
  } else {
    return null;
  }
};

export default withRouter(RootRouter);
