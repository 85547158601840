export const parseNewUserForm = values => {
  const contacts = [];
  for (const [key, value] of Object.entries(values)) {
    if (key.split('_')[0] === 'phone') {
      contacts.push({
        type: 'PHONE',
        phone: {
          number: value,
          countryCode: '+91',
        },
        isPrimary: contacts.length <= 0,
      });
    }

    if (key.split('_')[0] === 'email') {
      contacts.push({
        type: 'EMAIL',
        email: value,
        isPrimary: contacts.length <= 0,
      });
    }
  }
  const bankAccounts = [];
  if (values.bankName && values.accountNumber && values.ifscCode && values.accountType) {
    bankAccounts.push({
      bankName: values.bankName,
      accountNumber: values.accountNumber,
      ifscCode: values.ifscCode,
      accountType: values.accountType,
      primary: true,
    });
  }

  return {
    authContact: {
      number: values.authContact,
      countryCode: '+91',
    },
    userId: values.userId,
    pan: values.pan,
    dob: values.dob ? values.dob.format('DD-MM-YYYY') : null,
    contacts: contacts.length ? contacts : null,
    bankAccounts: bankAccounts,
    mfHoldings: values.mfHoldings ? JSON.parse(values.mfHoldings) : null,
    isLienMarkingMocked: !values.isLienMarkingEnabled,
  };
};

export const validateIfJson = async (rule, value, cb) => {
  if (!value) return;
  let parsedData = null;
  try {
    parsedData = JSON.parse(value);
  } catch (e) {}
  if (parsedData && typeof parsedData === 'object') cb();
  else cb('Value is not acceptible JSON');
};

export const lenderToStr = {
  bajaj_finserv: 'Bajaj finserv',
};

export function downloadPDF(blob, filename) {
  // Create object url from blob
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
