import React, { useState } from 'react'
import { Row, Col, Card, Button, Input, InputNumber, Typography } from 'antd';
import { checkLeadStatusAPI } from '../../../../utils/apiService';
import './Signup.css';
import { logSharedResponse, notifySdkError } from '../../../../utils';

const { Text } = Typography;

export default function Signup() {
    const [userName, updateUserName] = useState('');
    const [userEmail, updateUserEmail] = useState('');
    const [userContact, updateUserContact] = useState('');
    const [userPinCode, updateUserPinCode] = useState('');
    const [leadId, updateLeadId] = useState('');
    const [leadStatusResp, updateLeadStatusResp] = useState('');

    function handleSignup() {
        window.sc.signup({ name: userName, email: userEmail, contact: userContact, pinCode: userPinCode })
        .then(res => logSharedResponse('Signup Response', res))
        .catch(err => {
            notifySdkError(err);
            throw err;
        })

    }

    function checkStatus() {
        updateLeadStatusResp('');
        checkLeadStatusAPI(leadId)
        .then(resp => updateLeadStatusResp(resp.data));
    }

    return (
        <div className="container">
            <Card>
                <Row>
                    <Col sm={24} xl={12}>
                        <div className="actions-container">
                            <div>
                                <h1 className="action-header">Open Broker Account</h1>
                                <Row className="mb12">
                                    <Col xs={12} lg={8}>
                                        <h3 className=''>Name</h3>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        <Input
                                            placeholder="Ashok Kumar"
                                            value={userName}
                                            onChange={(ev) => updateUserName(ev.target.value)}
                                            className='input-item'
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb12">
                                    <Col xs={12} lg={8}>
                                        <h3 className=''>Email</h3>
                                    </Col>
                                    <Col xs={12} lg={16}>
                                        <Input
                                            placeholder="ashok.kumar@example.com"
                                            value={userEmail}
                                            onChange={(ev) => updateUserEmail(ev.target.value)}
                                            className='input-item'
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb12">
                                    <Col xs={12} md={8}>
                                        <h3 className=''>Contact</h3>
                                    </Col>
                                    <Col xs={12} md={16}>
                                        <InputNumber
                                            placeholder="9876543210"
                                            value={userContact}
                                            onChange={(value) => updateUserContact(value)}
                                            className='input-item'
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb12">
                                    <Col xs={12} md={8}>
                                        <h3 className=''>Pin Code</h3>
                                    </Col>
                                    <Col xs={12} md={16}>
                                        <Input
                                            placeholder="560025"
                                            value={userPinCode}
                                            onChange={(ev) => updateUserPinCode(ev.target.value)}
                                            className='input-item'
                                        />
                                    </Col>
                                </Row>

                            <Text type="secondary" align='end'>
                                * All parameters are optional
                            </Text>

                                <Button className="btn" type="primary" onClick={handleSignup}> Continue Signup </Button>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} xl={12} style={{flexGrow: 1, alignItems: 'stretch'}}>
                        <div className="actions-container">
                            <Row className="mb12">
                                <h1 className="action-header">Check Lead Status</h1>
                                <Col xs={12} lg={8}>
                                    <h3 className=''>Lead Id</h3>
                                </Col>
                                <Col xs={12} lg={16}>
                                    <Input
                                        placeholder="605b01d2832ac170727e6890"
                                        value={leadId}
                                        onChange={(ev) => updateLeadId(ev.target.value)}
                                        className='input-item'
                                    />
                                </Col>
                            </Row>

                            <Row className="mb12">
                                <Button type="secondary" className="btn" onClick={checkStatus}>
                                    Check Status
                                </Button>
                            </Row>
                            <Row>
                                <pre>
                                {
                                    leadStatusResp && JSON.stringify(leadStatusResp, null, 4)
                                }
                                </pre>
                            </Row>
                        </div>
                    </Col>
                </Row >
            </Card>
        </div>
    )
}