import React from 'react';
import { Card, Table } from 'antd';
import { SchemeDetailsColumns, ExitedTableColumns, PortfolioTableColumns, SchemeDetailsFolioColumns } from './MfSummaryTableColumns';

const getPortfolioDataArray = ({
  schemeCount,
  xirr,
}) => {
  return [
    {
      schemeCount,
      xirr,
    },
  ]
}

const expandedRowRender = (record) => {
  const folioRecords = record["folios"];
  const data = [];
  for (let i = 0; i < folioRecords.length; ++i) {
    data.push({
      folioNumber: folioRecords[i].folioNumber,
      brokerName: folioRecords[i].brokerName,
      brokerCode: folioRecords[i].brokerCode,
      isDemat: folioRecords[i].isDemat,
      units: folioRecords[i].units,
    });
  }
  return <Table columns={SchemeDetailsFolioColumns} dataSource={data} pagination={false} />;
};

const MfSummaryTable = ({ exited, portfolio, schemeDetails }) => {
  return (
    <Card title="Summary">
      <Table
          bordered
          title={() => <b>Portfolio</b>}
          size='small'
          pagination={false}
          columns={PortfolioTableColumns}
          dataSource={getPortfolioDataArray(portfolio)}
          scroll={{ x: 1000 }}
      />  
      <Table
          bordered
          className="mt8"
          title={() => <b>Exited</b>}
          size='small'
          columns={ExitedTableColumns}
          dataSource={exited}
          scroll={{ x: 2000 }}
      /> 
      <Table
          bordered
          className="mt8"
          title={() => <b>Scheme Details</b>}
          size='small'
          columns={SchemeDetailsColumns}
          dataSource={schemeDetails}
          scroll={{ x: 1000 }}
          expandedRowRender={expandedRowRender}
      /> 
    </Card>
  );
};

export default MfSummaryTable;
