import React from 'react';
import { FetchStateMap } from './utils';
import MfImportStatusTable from './MfImportStatusTable';
import MfSummaryTable from './MfSummaryTable';
import MfTransactionTable from './MfTransactionTable';
import MfUserInfoTable from './MfUserInfoTable';

const MfPostbackSection = ({ postbackFetchState, postBackData }) => {
    return <div>
        {(() => {
            switch (postbackFetchState) {
              case FetchStateMap.INITIAL:
                return <p className="mt8">Use a transaction id to search for postback data</p>;
              case FetchStateMap.EMPTY:
                return <p className="mt8">Couldnt find any data in postback, please wait and try again</p>;
              case FetchStateMap.LOADING:
                return <p className="mt8">Searching postback...</p>;
              case FetchStateMap.SUCCESS:
                return (
                  <>
                    <MfImportStatusTable postbackData={postBackData}
                    />
                    <MfSummaryTable
                      exited={postBackData.summary.exited}
                      portfolio={postBackData.summary.portfolio}
                      schemeDetails={postBackData.summary.schemeDetails}
                    />
                    <MfTransactionTable
                      transactions={postBackData.transactions}
                    />
                    <MfUserInfoTable userInfo={postBackData.userInfo} />
                  </>
                );
            }
          })()}
    </div> 
}

export default MfPostbackSection;
