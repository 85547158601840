import React from 'react';
import { Table } from 'antd';

const { Column, ColumnGroup } = Table;

const HoldingsV2Table = ({securities}) => {
  return (
    <Table bordered dataSource={securities} scroll={{ x: 1800 }}>
      <Column title="Name" dataIndex="name" key="name" fixed="left" width={180}/>
      <Column title="ISIN" dataIndex="isin" key="isin" />
      <Column title="NSE Ticker" dataIndex="nseTicker" key="nseTicker" />
      <ColumnGroup title="NSE Positions">
        <Column
          title="Shares"
          dataIndex="positions.nse.quantity"
          key="positions.nse.quantity"
        />
        <Column
          title="Avg. Price"
          dataIndex="positions.nse.averagePrice"
          key="positions.nse.averagePrice"
        />
      </ColumnGroup>
      <Column title="BSE Ticker" dataIndex="bseTicker" key="bseTicker" />
      <ColumnGroup title="BSE Positions">
        <Column
          title="Shares"
          dataIndex="positions.bse.quantity"
          key="positions.nse.quantity"
        />
        <Column
          title="Avg. Price"
          dataIndex="positions.bse.averagePrice"
          key="positions.nse.averagePrice"
        />
      </ColumnGroup>
      <ColumnGroup title="Holdings">
        <Column
          title="Shares"
          dataIndex="holdings.quantity"
          key="holdings.quantity"
        />
        <Column
          title="Avg. Price"
          dataIndex="holdings.averagePrice"
          key="holdings.averagePrice"
        />
      </ColumnGroup>
      <Column
        title="Transactable Qty"
        dataIndex="transactableQuantity"
        key="transactableQuantity"
      />
      <Column
        title="Smallcase Qty"
        dataIndex="smallcaseQuantity"
        key="smallcaseQuantity"
      />
    </Table>
  );
};

export default HoldingsV2Table;
