import React, { useState } from 'react';
import { Button, Card, Collapse, Form, Input, message } from 'antd';
import { validateIfJson } from './utils';

const { TextArea } = Input;
const { Panel } = Collapse;

const CreateOfferForm = ({ form, onSubmit, isLoading }) => {
  const { getFieldDecorator, validateFields } = form;
  const [jsonInput, setJsonInput] = useState('');
  const [isExpanded, setIsExpanded] = useState(false); 

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        try {
          const parsedJson = JSON.parse(values.offerJson);
          onSubmit(parsedJson);
          setIsExpanded(false); 
        } catch (error) {
          message.error('Invalid JSON format.');
        }
      }
    });
  };

  return (
    <Collapse activeKey={isExpanded ? '1' : null} onChange={handleToggle}>
      <Panel header="Create Offer" key="1">
        <Card>
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Offer Configuration (JSON)">
              {getFieldDecorator('offerJson', {
                rules: [
                  {
                    validator: validateIfJson,
                  },
                ],
                initialValue: jsonInput,
              })(<TextArea autoSize={{ minRows: 6 }} onChange={(e) => setJsonInput(e.target.value)} />)}
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Create Offer
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Panel>
    </Collapse>
  );
};

const WrappedCreateOfferForm = Form.create({ name: 'create-offer' })(CreateOfferForm);

export default WrappedCreateOfferForm;
