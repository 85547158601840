import React from 'react';
import { Button, Modal } from 'antd';
import ReactJson from 'react-json-view';

function showJsonInModal(title, data) {
  Modal.info({
    title,
    content: (
      <div className="response-data-content">
        <ReactJson src={data} />
      </div>
    ),
    className: 'response-data-modal',
    width: '100vw',
  });
}

const ResponseNotification = ({type, data}) => {
  return (
  <div>
    Received response from SDK
    <Button
      type="link"
      size="small"
      onClick={() => showJsonInModal(type, data)}
    >
      ▶ View Response
    </Button>
  </div>
)};
export default ResponseNotification;
