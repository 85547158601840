export const FetchStateMap = {
    INITIAL: 'INITIAL',
    LOADING: 'LOADING',
    EMPTY: 'EMPTY',
    SUCCESS: 'SUCCESS',
};

export const MFDataReducerActionType = {
    SET_NOTES: 'SET_NOTES',
    SET_FROM_DATE: 'SET_FROM_DATE',
    SET_PAN_NUMBER: 'SET_PAN_NUMBER',
    SET_MOBILE_NUMBER: 'SET_MOBILE_NUMBER',
    SET_RESPONSE_CONFIG: 'SET_RESPONSE_CONFIG',
}